import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function SettingsDialog(props) {
  const [name, setName] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [showDecl, setShowDecl] = React.useState(false);

  const { handleClose, dialogOpen, dName, id } = props;

  React.useEffect(() => {
    if (count == 0) {
      setName(dName);
    }
  });

  function saveData() {
    if (name != "") {
      handleClose({ name });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Имя секции</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setCount(count + 1);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>Сохранить</Button>
        <Button onClick={cancelData}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
