import React from "react";
import PropTypes from "prop-types";
import "../css/style.css";


import { connect } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CustomizedButton from "../_components/buttons/CustomizedButton";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import { userActions, appActions } from "../_actions";
import PartItem from "./PartItem";
import { userService, confService } from "../_services";
import { store, history } from "../_helpers";
import { storeData, setStore } from "../store.js";
import { SendButton, TextFieldCell, Snack } from "../_components";
import ncLogo from "../img/logo2.png";

const tmp_q = {
        "1": {
            "description": "Текст текстового вопроса",
            "required": true,
            "type": "text"
        },
        "5": {
            "description": "Текст вопроса со множественными ответами",
            "required": true,
            "type": "checkbox",
            "items": {
                "11": "пункт 1",
                "22": "пункт 2"
            }
        },
        "8": {
            "description": "Текст необязательного одиночного вопроса",
            "required": false,
            "type": "radio",
            "items": {
                "44": "пункт 1",
                "222": "пункт 2"
            }
        }
        };
        

const QuestToArray = (qDict) => 
{
    let keys = Object.keys(qDict);
    
    let qArray = [];
    for (let i=0;i<keys.length;i++)
    {
        qArray.push({...qDict[keys[i]],key:keys[i]})
    }
        
    return qArray;
}

const ShowCheckbox = (key,data,value,onChange) => {
    
    
    
     let keys = Object.keys(data);    
     let tmpArray = [];
    for (let i=0;i<keys.length;i++)
    {
        tmpArray.push({value:data[keys[i]],key:keys[i]})
    }
    
        const handleChange = (event) => {
             
             
             if (event.target.checked)
             {
                onChange("checkBox",key,[...value,event.target.name]);
             }
             else
             {
                 onChange("checkBox",key,value.filter(e =>e!=event.target.name));
             }
             
             
          };
    
    return(
          <FormGroup>
      {tmpArray.map(function(item){
                            
                            
                            return (
                            <FormControlLabel  
                            control={<Checkbox 
                                checked={value.indexOf(item.key)>=0} 
                                onChange={handleChange} 
                                  color="primary"
                                name={item.key} />}
                            label={item.value} />
                            );        
                          })
                          }
      
    </FormGroup>
    );
}

const ShowRadio = (key,data,value,onChange) => {
    
    let keys = Object.keys(data);    
     let tmpArray = [];
    for (let i=0;i<keys.length;i++)
    {
        tmpArray.push({value:data[keys[i]],key:keys[i]})
    }
    
         const handleChange = (event) => {
             
            //let val = (event.target as HTMLInputElement).value;
            onChange("radio",key,event.target.value);
          };
    
    
    return(
         <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                             value={value}
                            onChange={handleChange}
                          >
                          {tmpArray.map(function(item){
                            
                            return (
                            <FormControlLabel value={item.key} control={<Radio />} label={item.value} />
                            );        
                          })
                          }
                          </RadioGroup>
                        </FormControl>);
    
}

class VotingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, questions:[], answers:{}, isSent:false,vkey:null,vid:null };
  }

  componentDidMount() {


        const self = this;
       let urls = window.location.href.split("/");
       
       
      let vote_data = urls[urls.length - 1];
      
      
      let vote_id = vote_data.split('?')[0];
      let v_key = vote_data.split('=').[1];
	  this.setState({vid:vote_id,vkey:v_key});
      
      
    
    this.loadQuestions(vote_id,v_key)
    
  }
  
  
  loadQuestions = (id,key) => {
	  
	  console.log(localStorage.getItem('token')+" "+localStorage.getItem("navic_redirect_count"));
	  
	  const src_url = window.location.pathname+window.location.search;
	  
        let self = this;
        userService.getWrapper('api/vote/main?signature='+key+'&id='+id,
        (data) => {
			console.log(data.data);
			
			if (data.data.header)
				this.setState({title:data.data.header});
			
				let tmpQ = QuestToArray(data.data.questions);
			let keys = Object.keys(data.data.questions);
			let answers = {}
			for (let i=0;i<keys.length;i++)
					answers[keys[i]] = [];
	
		self.setState({title:data.data.header,questions:tmpQ,answers,error:''});
           //self.setState({quest:data.data.questions,title:data.data.header});
		   localStorage.setItem("navic_redirect","");
		   localStorage.setItem("navic_redirect_count","");
           
        },
        (error) => {
			
			console.log(localStorage.getItem("navic_redirect_count")+" "+localStorage.getItem("navic_user"))
			
			self.props.dispatch(userActions.set_error(error));
				
			if (localStorage.getItem("navic_redirect_count") == "")
			{
				this.setState({error:''});
			localStorage.setItem("navic_redirect",src_url);
			localStorage.setItem("navic_redirect_count","1");
			history.push('/login');
			}
			else if (localStorage.getItem("navic_redirect_count") == "1")
			{
				localStorage.setItem("navic_redirect",src_url);
				localStorage.setItem("navic_user","");
				localStorage.setItem("navic_pwd","");
				localStorage.setItem("navic_redirect_count","2");	
				history.push('/login');
			}
			else
			{
				this.setState({error:'Некорректная ссылка или у вас нет прав для просмотра этой страницы'});
				localStorage.setItem("navic_redirect","");
				setTimeout(()=>{localStorage.setItem("navic_redirect_count","")},2000);
			}
			
			
        }
      );
  }
  
  onChange = (tp,key,value) =>{
      
      let tmpA = this.state.answers;
      
      if (tp == "text")
      {
          tmpA[key] = [value];
          this.setState({answers:tmpA});
      }
      
       if (tp =="checkBox")
      {
           tmpA[key] = value;
           this.setState({answers:tmpA});
      }
      
      if (tp =="radio")
      {
          
          tmpA[key] = [value];
          this.setState({answers:tmpA});
      }
  }
  
  sendAns = () => {
      
      
	  let self =this;
	  
	  let reqKeys = this.state.questions.filter(e=>e.required==1).map(e=>''+e.key);
	  
	  
	  let res_dialog = false;
	  let isOk = true;
	  
      for (let i=0;i<reqKeys.length;i++)
		if (this.state.answers[reqKeys[i]].length==0)
	  {
		  isOk =false;
	  }
	  
	  if (isOk)
	  {
       res_dialog = window.confirm("Отправить ответы?");
	   
	  }
	  else
	  {
		  alert('Заполните необходимые поля (помечены звездочкой)');
	  }
	   
	   
	   
	   
    if (res_dialog == true) {
       
	   
	   
		   
        userService.postWrapper(
        'api/vote/main?signature='+this.state.vkey+'&id='+this.state.vid,
        {answers:this.state.answers},
        function (data) {
            console.log(data);
          
		  self.setState({isSent:true});
          
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error});
          }
        }
      );  
	   
    }
  }
  

  render() {
    const self = this;
    const { isLoading, questions, title, answers, error } = this.state;
    const { classes, user } = this.props;

    
    // {this.props.conf_name}

    if (this.state.isSent)
    {
         return (<div style={{ height: '100vh', display: 'flex',
    flexDirection: 'column',
   alignItems: 'center' ,
   justifyContent: 'center'}}> 
    <div style={{marginLeft: 30,marginRight:30, marginBottom: 20, fontSize:30}}>Спасибо, ваш ответ принят</div>
	    
  </div>);

    }
    else
    return (
      <div className="layout">
		<div style={{marginTop:10}}>
		<img src={ncLogo} />
		</div>
        <div className="layout__contains">
		<h1 className="layout__title">{title}</h1>
        </div>
        <div>
        {questions && questions.map(function(item,index)
            {
                
                return (<div style={{marginBottom: 20}} key={item.key}>
                <h3>{index+1}. {item.description}{item.required?"*":""} </h3>
                <div style={{marginTop:10}}>
                { item.type =="text" && <textarea style={{maxWidth: 1000}} 
                value={answers[item.key][0]} 
                onChange={(e)=>self.onChange("text",item.key,e.target.value)}/>}
                { item.type =="radio" && ShowRadio(item.key,item.items,answers[item.key],self.onChange)}
                { item.type =="checkbox" && ShowCheckbox(item.key,item.items,answers[item.key],self.onChange)}
                </div>
                </div>);
                
        })}
        
        {!error && <Button variant="contained" onClick={self.sendAns}>Отправить ответ</Button>}
		{error && <div style={{fontSize:30}}>{error}</div>}
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedVotingPage = connect(mapStateToProps)(VotingPage);
export { connectedVotingPage as VotingPage };
