import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

import i18next from "i18next";
import CookiePopup from "../MainPage/CookiePopup";
import { history, store } from "../_helpers";
import { confService } from "../_services";
import CompanyForm from "./CompanyForm";
import { CreateConfPage } from "./CreateConfPage";
import { ConfNavPage } from "./ConfNavPage";
import { ConfListPage } from "./ConfListPage";
import { TeamPage } from "../TeamPage/TeamPage";
import { RolePage } from "../TeamPage/RolePage";
import { ClientListPage, ClientConfPage } from "../ClientPages";
import { SpeakersPage } from "../SpeakersPage/SpeakersPage";
import { SponsorPage } from "../SponsorPage/SponsorPage";
import { BuildingsPage } from "../BuildingsPage/BuildingsPage";
import { PartPage } from "../PartPage/PartPage";
import { HelpPage } from "./HelpPage";
import { TasksPage } from "../Pages/TasksPage";
import { SettingsPage } from "./SettingsPage";
import { SuppPage } from "./SuppPage";
import { TicketsPage } from "../ClientPages/TicketsPage";


import CustomizedButton from "../_components/buttons/CustomizedButton";
import { SendButton, TextFieldCell, Snack, DeleteDialog } from "../_components";
import { DeleteButton } from "../_components/buttons/DeleteButton";
import { EditButton } from "../_components/buttons/EditButton";
import "../fonts/fonts.css";
import "../css/style.css";
import { cookieText } from "../_constants";

import { storeData, setStore } from "../store.js";
import { AppMenu } from "../_components/AppMenu";
import { HeadMenu } from "../_components/HeadMenu";
import { appActions, userActions } from "../_actions";
import TimeTable from "../TimePage/TimeTable";

const USER_ORG = "org";
const USER_MEMBER = "team_member";
const USER_STUDENT = "student";

function setCookie(name, value, options) {
  options = options || {};
  let { expires } = options;
  if (typeof expires === "number" && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }

  if (expires && expires.toUTCString) options.expires = expires.toUTCString();

  value = encodeURIComponent(value);
  let updatedCookie = `${name}=${value}`;
  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      companies: [],
      hoverIndex: -1,
      isEdit: false,
      userType: '',
      userName: "",
      email: "",
      err_type: "error",
      menuOpen: true,
      loadConfInfo: false,
      loadTicketInfo: false,
      confInfo: null,
      tid: 0,
      confName: "",
      tickets: [],
       vertical: 'top',
    horizontal: 'center',
    confsLoaded: false,
    userInfoLoaded: false,
    };
    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    const { userType } = this.state;
    const self = this;

    if (store.getState().user.error) {
      const err1 = `${store.getState().user.error}`;
      let err_tp = "error";

      if (store.getState().user.err_type)
        err_tp = store.getState().user.err_type;

      if (err1)
        this.setState({ snackText: err1, alertShow: true, err_type: err_tp });
      else
        this.setState({
          snackText: "Ошибка выполнения запроса",
          alertShow: true,
          err_type: "error",
        });

      setTimeout(() => {
        self.props.dispatch(userActions.disable_error());
      }, 100);

      // костыль, исправить и добавить потом  err_code
      if (err1.indexOf("401") >= 0 && err_tp == "error") history.push("/login");
    }

    if (store.getState().user.info && store.getState().user.info.type) {
        
        const userType = store.getState().user.info.type;
        
        if (userType != USER_STUDENT && !this.state.confsLoaded)
        {
            //this.setState({confsLoaded:true});
            //this.props.dispatch(userActions.conf_list());
        }
        
      this.setState({
        userType: userType,
        userName: store.getState().user.info.name,
        email: store.getState().user.info.email,
      });
    }
  };

  componentDidMount() {
    // alert(this.props.app.page);

    this.setLanguage(this.props.app.lang);

    let url1 = window.location.href;
    
     
     let pp = this.props.match;
     console.log(pp);

    const userStrData = localStorage.getItem("user");
    //const userStrData = null;

    if (userStrData && userStrData != "{}") {
      try {
        const objData = JSON.parse(userStrData);
        console.log(objData);
        this.props.dispatch(userActions.setuser(objData));
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(userStrData);
      this.props.dispatch(userActions.getuser());
    }

    const strData = localStorage.getItem("conf");
    if (strData != "null") {
      try {
        const objData = JSON.parse(strData);
        this.props.dispatch(userActions.set_conf(objData));
      } catch (e) {
        console.log(e);
      }
      
    }
    
	localStorage.setItem("navic_redirect_count","");
	
    const url = window.location.pathname;
    const self = this;

    
    if (url.indexOf("/purchased") >= 0) {
      const url_arr = url.replace("/purchased-", "").split("-");

      if (url_arr.length > 1) {
        this.setState({ tid: url_arr[1] });
        confService.confInfo(
          url_arr[0],
          (data) => {
            self.setState({
              confName: data.data.name,
              confInfo: data.data,
              loadConfInfo: true,
            });
            console.log(data.data);

            if (self.state.loadConfInfo)
              self.getConfTicket(
                data.data.name,
                self.state.tickets,
                url_arr[0]
              );
          },
          (err) => {
            console.log(err);
          }
        );

        confService.ticketsInfo(
          url_arr[0],
          (data) => {
            self.setState({
              tickets: data.data,
              confInfo: data.data,
              loadTicketInfo: true,
            });

            if (self.state.loadConfInfo)
              self.getConfTicket(self.state.confName, data.data, url_arr[0]);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else {
      const url1 = url.replace("/", "");

      const urls = url1.split("/");
      let url0 = urls[0];

      let id = 0;
      if (urls.length > 1) id = urls[1];

      if (url0 == "") url0 = "confs";

      this.props.dispatch(appActions.setpage(url0, id));

    }
    
        

    const { page } = this.props.app;
  }

  setLanguage(language) {
    i18next.init({
      lng: language,
      resources: require(`../_lang/${language}.json`),
    });

    this.props.dispatch(appActions.setlang(language));
  }

  getConfTicket = (name, tickets, conf_id) => {
    
    const { tid } = this.state;

    let tname = "";
    let tcost = "";

    for (let i = 0; i < tickets.length; i++) {
      if (tickets[i].id == tid) {
        tname = tickets[i].name;
        tcost = tickets[i].cost;
      }
    }

    if (tcost != "" && tcost > 0) {
      //alert(`Вы купили билет "${tname}" на конференцию "${name}"`);
      // this.props.dispatch(appActions.setpage("confs"));
      // history.push('/conf_view/'+conf_id);
      this.props.dispatch(userActions.set_error(`Вы купили билет "${tname}" на конференцию "${name}"`,'info'));
    } else {
      //alert(`Вы зарегистрировались на конференцию "${name}"`);
      // this.props.dispatch(appActions.setpage("confs"));
      // history.push('/conf_view/'+conf_id);
      this.props.dispatch(userActions.set_error(`Вы зарегистрировались на конференцию "${name}"`,'info'));
    }

    this.props.dispatch(appActions.setpage("conf_view", conf_id));
    // history.push('/conf_view/'+conf_id)
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };
  
  clientMenuPress = (page) => {
    
      
      this.props.dispatch(appActions.setpage(page));
      
  }

  render() {
    const self = this;
    const {
      userType,
      userName,
      menuOpen,
      email,
      alertShow,
      snackText,
      err_type,
      vertical,
      horizontal,
    } = this.state;
    const { page } = this.props.app;
    const { user } = this.props;
    
    // <AppMenu menu={this.state.menu} selectedMenu={this.state.location} open={this.state.open}/>
    
    if (window.location.href.indexOf('ticket-moderation')>=0 || window.location.href.indexOf('votes')>=0)
        return (<div></div>);

    if (userType == USER_STUDENT)
    {
        return(
        <div>
        <HeadMenu name={userName} email={email} type={userType} menuPress={this.clientMenuPress} />
          <div className="layout_main">
            {userType == USER_STUDENT && page == "confs" && <ClientListPage />}
            {userType == USER_STUDENT && page == "conf_view" && (
              <ClientConfPage />
            )}
            {userType == USER_STUDENT && page == "tickets" && <TicketsPage />}
            
          
          </div>
        </div>
        
        );
    }
    else
    return (
      <div style={{ height: "100vh", minWidth: 1600 }}>
        <AppMenu
          open={menuOpen}
          onOpen={() => self.setState({ menuOpen: !menuOpen })}
          userType={userType}
          conf={user.selectedConf}
        />
        <div className={menuOpen ? "wrapper__open" : "wrapper"}>
          <HeadMenu name={userName} email={email} type={userType} />
          <div className="layout_main">
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              (page == "events") && <ClientListPage />}

            {(userType == USER_ORG || userType == USER_MEMBER) &&
              (page == "confs" || page == "confs_title") && <ConfListPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "new_conf" && <CreateConfPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "conf_param" && <CreateConfPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) && page == "team_org" && (
              <TeamPage
                conf_id={user.selectedConf ? user.selectedConf.id : -1}
              />
            )}
            {(userType == USER_ORG || userType == USER_MEMBER) && page == "role_org" && (
              <RolePage
                conf_id={user.selectedConf ? user.selectedConf.id : -1}
              />
            )}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
            page == "client_view" && <ClientConfPage isOrg={true}/>}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "speakers" && <SpeakersPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "sponsors" && <SponsorPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "blds" && <BuildingsPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "partips" && (
                <PartPage
                  conf_id={
                    user.selectedConf && user.selectedConf.id
                      ? user.selectedConf.id
                      : -1
                  }
                  conf_name={
                    user.selectedConf && user.selectedConf.id
                      ? user.selectedConf.name
                      : ""
                  }
                />
              )}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "nav" && <ConfNavPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "conf_prog" && (
                <TimeTable
                  conf_data={user.selectedConf}
                  building_id={
                    user.selectedConf ? user.selectedConf.building_id : -1
                  }
                  conf_id={user.selectedConf ? user.selectedConf.id : -1}
                />
              )}

            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "helpinfo" && <HelpPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "settings" && <SettingsPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "support" && <SuppPage />}
            {(userType == USER_ORG || userType == USER_MEMBER) &&
              page == "tasks" && <TasksPage />}
          </div>
        </div>
        <Snack
          open={alertShow}
          variant={err_type}
          text={snackText}
          onClose={this.onCloseSnack}
        />
        {user.isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
        <CookiePopup />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;
  // const {user} = authentication;
  const { unresolvedAppealCount } = app;
  return {
    user,
    app,
    authentication,
  };
}

const connectedMainPage = connect(mapStateToProps)(MainPage);
export { connectedMainPage as MainPage };
