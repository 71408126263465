import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormatIcons from '../_components/FormatIcons';
import i18next from "i18next";
import "../css/style.css";
import onlineLogo from "../img/online.png";
import offlineLogo from "../img/offline.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 10,
  p: 4,
};

const no_text = "уточняется";
const no_url_text =
  "Смотреть трансляцию";

function replaceName(name)
{
	if (!name)
		return name;
    
    if (name.includes('Приветственный кофе-брейк для не проживающих в отеле'))
		return 'Приветственный кофе-брейк для не проживающих в отеле';
	
	if (name.indexOf('Обед')==0)
		return 'Обед';
	
	if (name.indexOf('кофе-брейк')==0)
		return 'кофе-брейк';
	
	
	return name;
}

  
function getUrls(s0)
{
    console.log(s0);
    if (!s0)
        return;
    let s = s0;
    
    var re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    
    let array = [...s.matchAll(re)];
    
    for (let i=0;i<array.length;i++)
    {
        let url_tmp = array[i][0]+'';
		let url_correct = url_tmp;
		
		
		if (!url_tmp.includes('https://') && !url_tmp.includes("http://"))
		{
			
			url_correct = "https://"+url_tmp;
		}
		//alert(url_correct);
        
         s0 = s0.replace(url_tmp,'<span style="color: blue" ><a href="'+url_correct+'" target="_blank">'+url_tmp+'</a></span>');
    }
    
    s0 = '<div>'+s0+'</div>';
    console.log(s0);
      return s0;

}


export default function SlotInfo(props) {
  const [count, setCount] = React.useState(0);
  const [openQ, setQ] = React.useState(false);
  const [qtext, setQtext] = React.useState('');

  const { handleClose, sendQ, data, open, hasTicket, isPublic, redirectToConf } = props;

  React.useEffect(() => {});

  function openUrl() {
    if (data.url_online) {
      if (data.url_online.indexOf("https:") != 0 && data.url_online.indexOf("http:") != 0)
        window.open("https://" + data.url_online);
    
      else window.open(data.url_online);
    }
  }
  
  function onSendQ(){
      
      if (qtext != '')
      {
          sendQ(qtext+" (вопрос для "+data.sp_str+")");
          setQ(false);
          setQtext('');
      }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <span style={{fontWeight:'700'}}>Тема:</span> {data.name ? replaceName(data.name) : no_text}
            
          </Typography>
          
          {data.description &&<Typography id="transition-modal-description" sx={{ mt: 2 }} style={{whiteSpace: 'pre-wrap' }}>
            <span style={{fontWeight:'700'}}>Описание:</span> 
            <div  dangerouslySetInnerHTML={{__html:  getUrls(data.description)}}>
            </div>
          </Typography>}
          
          {data.slot_type != "service_lesson" && <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <span style={{fontWeight:'700'}}>Спикеры:</span> {data.sp_str ? data.sp_str : no_text}
          </Typography>}
          {data.type != "online" && <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <span style={{fontWeight:'700'}}>Зал:</span> {data.room_number ? data.room_number : no_text}
          </Typography>}
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <span style={{fontWeight:'700'}}>Время начала:</span> {data.time ? data.time : no_text}
          </Typography>
          { data.slot_type != "service_lesson" && <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }} >
          <span >
            <span style={{fontWeight:'700'}}>Формат:</span> {data.type == "online" && i18next.t("online_text")} 
                    {data.type == "offline" && i18next.t("offline_text")}
                    {data.type == "hybrid" && i18next.t("hybrid_text")}
                    </span>
                    {(data.type == "online" || data.type == "hybrid") && <img style={{marginLeft:10}}
                    src={onlineLogo} />}
                    {(data.type == "offline" || data.type == "hybrid") && <img style={{marginLeft:10}}
                    src={offlineLogo} />}
            </div>
          </Typography>}
          
          {data.url_online && !isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ color: "blue", textDecoration: "underline", cursor:'pointer' }}
            >
              <span onClick={openUrl}>Ссылка на трансляцию</span>
            </Typography>
          )}

          {data.url_online && isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <span onClick={redirectToConf}>{no_url_text}</span>
            </Typography>
          )}
          {hasTicket && <div style={{marginTop:10}}>
          {(!openQ && data.slot_type != "service_lesson") && <Button onClick={()=>setQ(true)}>Задать вопрос спикеру</Button>}
          
          {openQ && <div style={{marginTop: 10}}>
                <span>Введите текст вопроса</span>
              <textarea
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="q"
					  multiline
                      onChange={(e)=>setQtext(e.target.value)}
                      value={qtext}
                      style={{marginTop:5}}
                    />
              <Button onClick={onSendQ}>Отправить</Button>
              <Button onClick={()=>{ setQ(false); setQtext('')}}>Отмена</Button>
            </div>}
          </div>}
        </Box>
      </Fade>
    </Modal>
  );
}
