import React from "react";
import PropTypes from "prop-types";

import "../css/style.css";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CookieConsent, { Cookies } from "react-cookie-consent";

export default class CookiePopup extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="Согласен"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        style={{
          paddingLeft: 130,
          zIndex: 9000,
        }}
        buttonStyle={{
          position: "absolute",
          left: 10,
          top: -3,
          backgroundColor: "#33AFEE",
        }}
      >
        Продолжая пользоваться сайтом, вы даёте{" "}
        <a
          href="https://naviconf.com/cookie-agreement/"
          target="_blank"
          rel="nofollow"
          rel="noindex"
        >
          <span style={{ textDecoration: "underline" }}>cогласие</span>
        </a>{" "}
        на автоматический сбор и анализ ваших данных, необходимых для работы
        сайта и его улучшения, а также использование файлов{" "}
        <a
          href="https://naviconf.com/cookie/"
          target="_blank"
          rel="nofollow"
          rel="noindex"
          class="hrefline"
        >
          <span style={{ textDecoration: "underline" }}>cookie</span>
        </a>
      </CookieConsent>
    );
  }
}
