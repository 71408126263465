import React from "react";
import PropTypes from "prop-types";
import "../css/style.css";
import { connect } from "react-redux";


import { userActions, appActions } from "../_actions";

import ClientConfItem from "./ClientConfItem";
import { store, history } from "../_helpers";


class ClientListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, isPublic: false };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    if (window.location.href.indexOf("events") >= 0) {
      this.setState({ isPublic: true });

    }
    
    //history.push('/login');

    this.props.dispatch(userActions.conf_student_list());
    // confService.confListParticipants(true);
  }

  storeChange = (e) => {};

  onItemClick = (e) => {};

  onItemEdit = (e) => {
    if (!this.state.isPublic) {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("conf_view", e.id));
    } else {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("event", e.id));
    }
  };

  onItemDelete = (e) => {};

  render() {
    const self = this;
    const { isLoading } = this.state;
    const { classes, user } = this.props;
    console.log(user);
    
    let isMobile = window.innerWidth<800;
    console.log(isMobile,window);

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Список Конференций</h1>
        </div>

        <ul className="conf-list">
          {user.conflist_student.length > 0 &&
            user.conflist_student.map((item, index) => (
              <ClientConfItem
                data={item}
                key={index}
                onClick={() => self.onItemClick(item.id)}
                onEdit={() => self.onItemEdit(item)}
                onDelete={() => self.onItemDelete(item.id)}
                isMobile={isMobile}
              />
            ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedClientListPage = connect(mapStateToProps)(ClientListPage);
export { connectedClientListPage as ClientListPage };
