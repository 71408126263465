import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { userService } from "../_services";

import "../css/style.css";

export default function TicketDialog(props) {
  const [loading, setLoad] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [curSp, setCS] = React.useState(null);
  const [splist, setSplist] = React.useState([]);

  const [count, setCount] = React.useState(0);

  const { handleClose, tickets, dialogOpen } = props;

  React.useEffect(() => {});

  function cancelData() {
    handleClose(-1);
    setCount(0);
  }

  function selectTicket(id) {
    handleClose(id);
    setCount(0);
  }


  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Виды билетов</DialogTitle>
      <DialogContent style={{ minwidth: 300, maxWidth:600 }}>
        <div>
          {tickets.length > 0 &&
            tickets.map((item, index) => (
              <button
                className="btn-border"
                style={{ marginBottom: 10, minWidth: 250,maxWidth:400 }}
                onClick={() => selectTicket(item.id)}
              >
                <span>
                  {item.name}{item.cost>0 ? ","+item.cost+"  руб": ""} 
                </span>
              </button>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelData}>Закрыть</Button>
      </DialogActions>
      {loading && (
        <div className="shadow">
          <div className="shadow_circle">
            <CircularProgress />
          </div>
        </div>
      )}
    </Dialog>
  );
}
