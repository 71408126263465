import React from "react";
import PropTypes from "prop-types";

import "../css/style.css";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import i18next from "i18next";
import { SendButton, TextFieldCell, Snack } from "../_components";
import HelpInfo from "./HelpInfo";

export class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {
    const self = this;
    const { isLoading } = this.state;

    return (
      <div className="layout">
        <h1 className="layout__title">Руководство</h1>
        <div>Здесь будет текст руководства пользователя</div>
        <HelpInfo />
      </div>
    );
  }
}
