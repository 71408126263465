import { appConstants } from "../_constants";

const initialState = {
  page: "confs",
  lang: "ru",
};

export function app(state = initialState, action) {
  if (action.type === appConstants.UPDATE_API_HOST) {
    return {
      ...state,
      apiHost: action.host,
    };
  }
  if (action.type === appConstants.SET_MENU) {
    return {
      ...state,
      page: action.page,
    };
  }
  if (action.type === appConstants.SET_LANG) {
    return {
      ...state,
      lang: action.lang,
    };
  }

  return state;
}
