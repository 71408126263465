import React from "react";
import PropTypes from "prop-types";
import "../css/style.css";

import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userService } from "../_services";
import { confService } from "../_services";
import { scheduleService} from "../_services";
import BldInfo from "./BldInfo";
import BldDialog from "../MainPage/BldDialog";

import BuildingsItem from "./BuildingsItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { userActions, appActions } from "../_actions";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import { SendButton, TextFieldCell, Snack } from "../_components";
import { history } from "../_helpers";

const TableHeaderItem = ({text})=> {
    
    return(
        <span style={{fontSize:16, fontWeight:600, textDecoration:'underline'}}>{text}</span> 
    );
}

class BuildingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      blds: [],
      bldId: 0,
      dName: "",
      dAddress: "",
      dMail: "",
      bldDialog: false,
      personId: 0,
      commentId: 0,
      slotInfoOpen: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    this.getBuildings();
    
  }
  
  

  getBuildings = () => {
      
    scheduleService.getRoomList();
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        console.log(data);
        if (data.data) {
            let rData = [...data.data];
            
            for (let i=0;i<rData.length;i++)
            {
                if (rData[i].comments.length>0)
                {
                    rData[i].comment = rData[i].comments[0].text;
                    rData[i].comment_id = rData[i].comments[0].id;
                    rData[i].user_id = rData[i].comments[0].user_id;
                }
                
                if (rData[i].contacts.length>0)
                {
                    rData[i].person = rData[i].contacts[0].person;
                    rData[i].phone = rData[i].contacts[0].phone;
                    rData[i].person_id = rData[i].contacts[0].id;
                    rData[i].user_id = rData[i].contacts[0].user_id;
                }
            }
            
          self.setState({ blds: rData });
          
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  storeChange = (e) => {};

  addBld = () => {
    this.setState({
      bldId: 0,
      dAddress: "",
      dName: "",
      dEmail: "",
      dPerson: "",
      dPhone: "",
      dComment: "",
      bldDialog: true,
    });
  };

  delBld = (id) => {
      
      
    const self = this;
    userService.delWrapper(
      `api/building/${id}`,
      (data) => {
        if (data.status == 200) self.getBuildings();
      },
      (error) => {
          
        if (error != "") {
          self.setState({ isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  editBld = (id) => {
    const { blds } = this.state;
    let bldAddr = "";
    let bldName = "";
    let bldEmail = "";
    let bldPhone = "";
    let bldPerson = "";
    let bldComment = "";
    let commentId = 0;
    let personId = 0;
    for (let i = 0; i < blds.length; i++) {
      if (blds[i].id == id) {
          console.log(blds[i]);
        bldAddr = blds[i].address;
        bldName = blds[i].name;
        bldEmail = blds[i].email;
        bldPhone = blds[i].phone;
        bldPerson = blds[i].person;
        bldComment = blds[i].comment;
        personId = blds[i].person_id?blds[i].person_id:0;
        commentId = blds[i].comment_id?blds[i].comment_id:0;
      }
    }

    this.setState({
      bldId: id,
      dAddress: bldAddr,
      dName: bldName,
      dEmail: bldEmail,
      dPerson: bldPerson,
      dPhone: bldPhone,
      dComment: bldComment,
      bldDialog: true,
      commentId: commentId,
      personId: personId,
    });
  };

  closeBldDialog = (val) => {
    const self = this;
    const { bldId, dName, dAddress, dEmail, dPerson, dPhone, dComment, commentId, personId } = this.state;
    console.log(val);

    const post_data = {};

    if (val.address) post_data.address = val.address;
    if (val.name) post_data.name = val.name;
    if (val.email) post_data.email = val.email;
//    else post_data.email = '';
    
    let isUpdate = false;
    
    if (val.email &&  confService.validateEmail(val.email) == false) {
        
        self.setState({bldDialog: false });
        self.props.dispatch(userActions.set_error('Введите корректный email','warning'));
        return;
    }
    
    if (post_data && post_data.name && (val.name != dName || val.address != dAddress || val.email!=dEmail )) {
      if (bldId > 0) {
        isUpdate = true;
        userService.patchWrapperBody(
          `api/building/${bldId}`,
          post_data,
          (data) => {
            console.log(data);
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      } else {
          
          if (val.phone) post_data.phone = val.phone;
          if (val.person) post_data.person = val.person;
          if (val.comment) post_data.comment = val.comment;
          
        userService.postWrapper(
          "api/building",
          post_data,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      }
      
      this.setState({ bldDialog: false });
    } 
    
    if (bldId>0 && post_data && post_data.name && (val.person != dPerson || val.phone != dPhone)) {
        
        isUpdate= true;
        let cData = {
            entity_type:"building",
            entity_id: bldId,
            person: val.person,
            phone: val.phone,
            
        };
        
        if (!dPerson && !dPhone)
        {
            console.log(val);
            userService.postWrapper(
          "api/contact",
          cData,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
            
        }
        else if (!val.person && !val.phone)
        {
            let id = personId;
            
            if (id>0)
            userService.delWrapper(
              `api/contact/${id}`,
              (data) => {
                if (data.status == 200) self.getBuildings();
              },
              (error) => {
                  
                if (error != "") {
                  self.setState({ isLoading: false });
                  self.props.dispatch(userActions.set_error(error))
                }
              }
            );
            
        }
        else
        {
            let id = personId;
            
            if (id>0)
            userService.patchWrapperBody(
              `api/contact/${id}`,
                  {person:val.person,phone:val.phone},
              (data) => {
                if (data.status == 200) self.getBuildings();
              },
              (error) => {
                  
                if (error != "") {
                  self.setState({ isLoading: false });
                  self.props.dispatch(userActions.set_error(error))
                }
              }
            );
        }
            
      
      this.setState({ bldDialog: false });      
    }
    
     if (bldId>0 && post_data && post_data.name && val.comment != dComment) {
        
        isUpdate= true;
        let cData = {
            entity_type:"building",
            entity_id: bldId,
            text: val.comment,
            };
        
        if (!dComment)
        {
            console.log(val);
            userService.postWrapper(
          "api/comment",
          cData,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
            
        }
        else if (!val.comment)
        {
            let id = commentId;
            
            if (id>0)
            userService.delWrapper(
              `api/comment/${id}`,
              (data) => {
                if (data.status == 200) self.getBuildings();
              },
              (error) => {
                  
                if (error != "") {
                  self.setState({ isLoading: false });
                  self.props.dispatch(userActions.set_error(error))
                }
              }
            );
            
        }
        else
        {
            let id = commentId;
            
            if (id>0)
            userService.patchWrapperBody(
              `api/comment/${id}`,
                  {text:val.comment},
              (data) => {
                if (data.status == 200) self.getBuildings();
              },
              (error) => {
                  
                if (error != "") {
                  self.setState({ isLoading: false });
                  self.props.dispatch(userActions.set_error(error))
                }
              }
            );
        }
            
      
      this.setState({ bldDialog: false });      
    }
      
      
    if (!isUpdate)  
        this.setState({ bldDialog: false });
    
  };

  render() {
    const self = this;
    const {
      isLoading,
      blds,
      dName,
      dAddress,
      dEmail,
      dPhone,
      dPerson,
      dComment,
      bldDialog,
      bldId,
      slotInfoOpen,
    } = this.state;
    const { classes } = this.props;
      const { rooms } = this.props.schedule;
      
    //<input type="text" placeholder="Поиск по названию" />
    //<input type="text" placeholder="Поиск по email" />
    //<input type="text" placeholder="Поиск по адресу" />
    //<input type="text" placeholder="Поиск по контактному лицу" />
    //<input type="text" placeholder="Поиск по телефону" />
    //<input type="text" placeholder="Поиск по комментарию" />

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Площадки</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addBld}>
              Новая площадка
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                
                <TableHeaderItem text="Название"/>
                
              </div>
              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text="Email"/>
              </div>

              <div className="tbl__item tbl__item--city">
                <TableHeaderItem text="Адрес"/>
              </div>
              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text="Контактное лицо"/>
              </div>

              <div className="tbl__item tbl__item--text">
                <TableHeaderItem text="Телефон"/>
              </div>
              
              <div className="tbl__item tbl__item--city">
                <TableHeaderItem text="Комментарий"/>
              </div>

              <div style={{ width: 60 }} />
            </div>
          </div>

          <div className="tbl__body">
            {blds &&
              blds.map((item, index) => (
                <BuildingsItem
                  data={item}
                  isUser
                  editBld={(e) => self.editBld(e)}
                  delBld={(e) => self.delBld(e)}
                  onClick={() =>
                    self.setState({ bldId:item.id, slotInfoOpen: true })
                }
                />
              ))}
          </div>
        </div>

        <BldDialog
          handleClose={self.closeBldDialog}
          id={bldId}
          dAddress={dAddress}
          dName={dName}
          dEmail={dEmail}
          dPhone={dPhone}
          dPerson={dPerson}
          dComment={dComment}
          dialogOpen={bldDialog}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
          <BldInfo
          handleClose={() => self.setState({ slotInfoOpen: false })}
          data={rooms}
          id={bldId}
          open={slotInfoOpen}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
        schedule,
  };
}

const connectedTeamPage = connect(mapStateToProps)(BuildingsPage);
export { connectedTeamPage as BuildingsPage };
