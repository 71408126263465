import React from "react";
import PropTypes from "prop-types";
import { history, store } from "../_helpers";
import "../css/style.css";
import "../css/grid.css";
import { connect } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

import { userActions, appActions } from "../_actions";
import i18next from "i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import TicketDialog from "./TicketDialog";
import QRDialog from "./QRDialog";
import UserInfoDialog from "./UserInfoDialog";
import SlotInfo from "./SlotInfo";
import SpeakerInfo from "./SpeakerInfo";
import SponsorInfo from "./SponsorInfo";
import ContactInfo from "./ContactInfo";


import StarActive from "../_components/StarActive";
import StarNotActive from "../_components/StarNotActive";
import { userService, confService, scheduleService } from "../_services";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";

import { serverUrl } from "../_constants";
import playLogo from "../img/play.png";
import inetLogo from "../img/inet.png";
import personLogo from "../img/person.png";
import onlineLogo from "../img/online.png";
import offlineLogo from "../img/offline.png";
import confLogo from "../img/conf_logo.png";

const nodata_text = "уточняется";

const buy_text = "Купить билет";
const get_text = "Получить билет";
const to_list_text = "К списку конференций";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function replaceName(name) {
  if (!name) return name;
  
  	if (name.includes('Приветственный кофе-брейк для не проживающих в отеле'))
		return 'Приветственный кофе-брейк для не проживающих в отеле';
	
	if (name.indexOf('Обед')==0)
		return 'Обед';
	
	if (name.indexOf('кофе-брейк')==0)
		return 'кофе-брейк';
	

  return name;
}

function getStorage(name) {
  return localStorage.getItem(name);
}

function setStorage(name, val) {
  localStorage.setItem(name, val);
}

//const NO_SECTION = "Без секции";
const NO_SECTION = "";

const getDate = (time) => {
  if (!time) return "уточняется";

  const time1 = time.substr(0, 10);

  return time1.split("-").reverse().join("-");
};

class ClientConfPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPublic: false,
      isErr: "",
      isLoading: false,
      addr: "",
      sponsors: [],
      scheduleData: [],
      schFav: [],
      hasTicket: false,
      oneDaySchedule: [],
      curDateIndex: 0,
      table_class: "col-md-4",
      tickets: [],
      openTicket: false,
      slotInfoOpen: false,
      speakerInfoOpen: false,
      sponsorInfoOpen: false,
      slotData: {},
      speakerData: {},
      sponsorData: {},
      favChedule: [],
      blds: [],
      id: 0,
      groupBySections: true,
      lessons: [],
      isUrl: false,
      urlt: "",
      showInfoDialog: false,
      showQRDialog: false,
      needFields: [],
      oneColumn: true,
      speakers: [],
      isMobile: false,
      speakersOpen: false,
      openQR: false,
      ticketData: null,
      ticketsInfo: [],
      qrData: "",
      editSpeakerOrder: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    let lang = "ru";

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });

    const { selectedConf, info } = this.props.user;

    this.setState({ isMobile: window.screen.width < 600 });

    if (window.location.href.indexOf("_view") < 0) return;

    if (window.location.href.indexOf("event") < 0) {
      let urls = window.location.href.split("/");

      let conf_id = urls[urls.length - 1];

      this.getConfInfo(conf_id);
      this.getSchedule(conf_id);
      //scheduleService.getSpeakerList(conf_id);
      this.getUrlT(conf_id);
      this.setState({ id: conf_id, isPublic: false });
    } else {
      let conf_id = 0;

      let urls = window.location.href.split("/");

      conf_id = urls[urls.length - 1];

      if (conf_id != 0) {
        this.setState({ id: conf_id, isPublic: true });
        this.getSchedule(conf_id);
        //scheduleService.getSpeakerList(conf_id);
        this.getConfInfo(conf_id, true);

        // userService.getAsync('api/conference/'+selectedConf.id+'/students');
      }
    }
  }

  isNavigateEnabled = () => {
    const {
      navigation_building_description,
      navigation_building_image,
      map_description,
      map_image,
      navigation_description,
      navigation_image,
    } = this.state;

    return (
      navigation_building_description ||
      navigation_building_image ||
      map_description ||
      map_image ||
      navigation_description ||
      navigation_image
    );
  };

  getConfInfo = (id, isPublic = false) => {
    const self = this;

    this.getTicketByConf(id);

    confService.confInfo(
      id,
      (data) => {
        if (data.status == 200) {
          self.setState({
            name: data.data.name,
            format: data.data.type,
            contacts: data.data.contacts,
            comment: data.data.description,
            streamUrl: data.data.navigation_description,
            image: data.data.image
              ? data.data.image.replace("public", "storage")
              : null,
            building_id: data.data.building_id,
            start_time: data.data.start_time,
            end_time: data.data.end_time,
            selectedBldId: data.data.building_id,
            map_description: data.data.map_description,
            map_image: data.data.map_image,
            map_link: data.data.map_link,
            addr: data.data.building
              ? data.data.building.address
              : "Не определен",
            navigation_building_description:
              data.data.navigation_building_description,
            navigation_building_image: data.data.navigation_building_image,
            navigation_description: data.data.navigation_description,
            navigation_image: data.data.navigation_image,
            tickets: data.data.ticket_types,
            sponsors: data.data.sponsors,
            wifi: data.data.wifi,
            hasTicket: data.data.hasTicket || self.props.isOrg,
          });

          let is_free = true;
          for (let i = 0; i < data.data.ticket_types.length; i++)
            if (data.data.ticket_types[i].cost) {
              is_free = false;
              break;
            }

          if (is_free || isPublic) self.setState({ ticket_text: get_text });
          else self.setState({ ticket_text: buy_text });
        }
      },
      (error) => {
        self.setState({ isErr: true });
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  getTicketQR = (id) => {
    const { classes, dispatch } = this.props;

    userService.getWrapper(
      "api/ticket/" + id,
      (data) => {
        console.log(data);
        if (data.data.img) {
          this.setState({ qrData: data.data.img, openQR: true });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  getTicketByConf = (id) => {
    const { classes, dispatch } = this.props;

    userService.getWrapper(
      "api/ticket?conference_id=" + id,
      (data) => {
        this.setState({ ticketsInfo: Object.values(data.data) });
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  getUrlT = (id) => {
    let { urlt } = this.state;
    const { classes, dispatch } = this.props;

    let self = this;

    userService.getWrapper(
      "api/link?conference_id=" + id,
      (data) => {
        if (data.data.length > 0) {
          let { id, redirect } = data.data[0];
          self.setState({
            url_id: id,
            urlt: redirect,
            url_old: redirect,
            isUrl: true,
          });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  sendQ = (text) => {
    const { slotData } = this.state;
    let self = this;

    if (slotData && slotData.id) {
      let post_data = { lesson_id: slotData.id, description: text };
      userService.postWrapper(
        "api/question",
        post_data,
        function (data) {
          console.log(data);
          alert("Вопрос успешно отправлен");
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error });
          }
        }
      );
    }
  };

  redirectToConf = () => {
    const { isPublic, id } = this.state;

    let token = getStorage("token");
    if (token) {
      history.push("/conf_view/" + id);
    } else {
      localStorage.setItem("navic_redirect", "/conf_view/" + id);
      history.push("/login");
    }
  };

  closeTicketDialog = (tid) => {
    this.setState({ openTicket: false });

    const { user } = this.props;
    const { isPublic, id } = this.state;
    const self = this;

    if (tid > 0) {
      if (isPublic) {
        self.redirectToConf();
      } else if (user.info.type && user.info.type != "org") {
        userService.getWrapper(
          "api/buy-ticket/" + tid,
          (data) => {
            //console.log(data);
            let need_fld = [...data.data.need_fill_anketa];
            let redirectUrl = data.data.redirect;
            if (need_fld.length > 0) {
              self.setState({
                showInfoDialog: true,
                needFields: need_fld,
                ticketRedirect: redirectUrl,
              });
            } else {
              //const url = `${serverUrl}buy-ticket/${user.info.id}/${tid}`;
              window.location.href = redirectUrl;
            }
          },
          (error) => {
            self.props.dispatch(userActions.set_error(error));
          }
        );

        /*
        
        const url = `${serverUrl}buy-ticket/${user.info.id}/${tid}`;
        window.location.href = url;
        */
      } else {
        alert("Нельзя купить билет из панели организатора");
      }
    }
  };

  setSchedule = (data, groupType) => {
    const months = {
      "01": "января",
      "02": "февраля",
      "03": "марта",
      "04": "апреля",
      "05": "мая",
      "06": "июня",
      "07": "июля",
      "08": "августа",
      "09": "сентября",
      10: "октября",
      11: "ноября",
      12: "декабря",
    };

    console.log(data);

    const { groupBySections, isPublic } = this.state;

    let speakers_dict = {};

    if (groupType == undefined) groupType = groupBySections;

    const schedule = [];
    const dates = [];
    const rooms = [];
    const favChedule = [];

    let t_class = "col-md-12";

    if (!isPublic)
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].is_favorite == true ||
          (data[i].entity && data[i].entity.is_favorite)
        ) {
          let objTmp = {};

          if (data[i].entity) {
            objTmp = {
              ...data[i],
              name: data[i].entity.name,
              type: data[i].entity.type,
              is_favorite: data[i].entity.is_favorite,
              id: data[i].entity.id,
              room_number:data[i].room_number,
            };
          } else {
            objTmp = { ...data[i] };
          }

          let sp_str = "";
          let speakers = data[i].entity
            ? data[i].entity.speakers
            : data[i].speakers;

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          objTmp.sp_str = sp_str;

          let timeStr = "";
          let tmpTime = [];
          if (objTmp.start_time) {
            tmpTime = objTmp.start_time.split(":");
            if (tmpTime.length > 1) {
              timeStr = `${tmpTime[0]}:${tmpTime[1]}`;

              objTmp.ftime = timeStr;
              objTmp.time = timeStr;
            }
          }

          if (objTmp.date) {
            timeStr = "";
            let date0 = objTmp.date.substr(0, 10);
            tmpTime = date0.split("-");
            if (tmpTime.length > 2) {
              timeStr = `${tmpTime[2]} ${months[tmpTime[1]]}`;
              objTmp.fdate = timeStr;
            }
          }

          favChedule.push({ ...objTmp });
        }
      }

    console.log(favChedule);

    console.log(data);

    for (let i = 0; i < data.length; i++) {
      console.log(i, data[i]);

      if (data[i].date) {
        let oldDate = new Date(data[i].date);

        console.log("OD", oldDate, data[i]);

        let speakers = [];

        if (data[i].entity && data[i].entity.speakers)
          speakers = [...data[i].entity.speakers];

        if (data[i].speakers) speakers = [...data[i].speakers];

        console.log(speakers);

        for (let m = 0; m < speakers.length; m++) {
          if (!speakers_dict[speakers[m].id])
            speakers_dict[speakers[m].id] = { ...speakers[m] };
        }

        let dayData = "" + oldDate.getDate();
        if (dayData.length < 2) dayData = "0" + dayData;

        let mData = "" + (1 + oldDate.getMonth());
        if (mData.length < 2) mData = "0" + mData;

        let cdate = `${oldDate.getFullYear()}-${mData}-${dayData}`;

        let ctime = data[i].start_time;
        let time = "";

        if (ctime) {
          ctime = ctime.split(":");

          if (ctime.length > 0) time = `${ctime[0]}:${ctime[1]}`;
        }

        let date_array = cdate.substr(0, 10).split("-");

        let title = `${date_array[2]} ${months[date_array[1]]}`;

        let dateIndex = -1;

        for (let j = 0; j < dates.length; j++)
          if (dates[j].date == cdate) {
            dateIndex = j;
          }

        if (dateIndex < 0) {
          if (groupType == false)
            dates.push({ date: cdate, title: title, rooms: [] });
          else dates.push({ date: cdate, title: title, sections: [] });
          dateIndex = dates.length - 1;
        }

        console.log(dates, groupType);

        // смотрим как группировать дальше

        if (groupType == false) {
          let roomIndex = -1;

          for (let l = 0; l < dates[dateIndex].rooms.length; l++)
            if (dates[dateIndex].rooms[l].room_number == data[i].room_number)
              roomIndex = l;

          if (roomIndex < 0) {
            dates[dateIndex].rooms.push({
              room_id: data[i].room_id,
              room_number: data[i].room_number,
              sections: [],
            });
            roomIndex = dates[dateIndex].rooms.length - 1;
          }

          let sectIndex = -1;

          for (
            let k = 0;
            k < dates[dateIndex].rooms[roomIndex].sections.length;
            k++
          )
            if (data[i].section) {
              if (
                dates[dateIndex].rooms[roomIndex].sections[k].id ==
                data[i].section.id
              )
                sectIndex = k;
            } else {
              if (dates[dateIndex].rooms[roomIndex].sections[k].id == 0)
                sectIndex = k;
            }

          if (sectIndex < 0) {
            if (data[i].section)
              dates[dateIndex].rooms[roomIndex].sections.push({
                id: data[i].section.id,
                name: data[i].section.name,
                slots: [],
              });
            else
              dates[dateIndex].rooms[roomIndex].sections.push({
                id: 0,
                name: NO_SECTION,
                slots: [],
              });
            sectIndex = dates[dateIndex].rooms[roomIndex].sections.length - 1;
          }

          let sp_str = "";

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          let theme_obj = {};

          if (data[i].entity) {
            theme_obj = {
              name: data[i].entity.name,
              type: data[i].entity.type,
              is_favorite: data[i].entity.is_favorite,
              description: data[i].description,
              id: data[i].entity.id,
              slot_type: data[i].slot_type,
              room_number:data[i].room_number,
            };
          } else {
            theme_obj = { ...data[i] };
          }

          let tmpObj = {
            ...theme_obj,
            time: time,
            sp_str: sp_str,
          };

          console.log(tmpObj);
          dates[dateIndex].rooms[roomIndex].sections[sectIndex].slots.push(
            tmpObj
          );

          if (dates[0].rooms.length == 2) t_class = "col-md-6";
          if (dates[0].rooms.length > 2) t_class = "col-md-4";
          if (dates[0].rooms.length > 3) t_class = "col-md-3";
          if (dates[0].rooms.length > 4) t_class = "col-md-2";
          //if (dates[0].rooms.length > 6) t_class = 'col-md-1';
        } else {
          let sectIndex = -1;

          console.log("DD", dateIndex, dates[dateIndex]);

          for (let k = 0; k < dates[dateIndex].sections.length; k++)
            if (data[i].section) {
              if (dates[dateIndex].sections[k].id == data[i].section.id)
                sectIndex = k;
            } else {
              if (dates[dateIndex].sections[k].id == 0) sectIndex = k;
            }

          if (sectIndex < 0) {
            console.log(data[i]);

            if (data[i].section)
              dates[dateIndex].sections.push({
                id: data[i].section.id,
                name: data[i].section.name,
                slots: [],
              });
            else
              dates[dateIndex].sections.push({
                id: 0,
                name: NO_SECTION,
                slots: [],
              });

            sectIndex = dates[dateIndex].sections.length - 1;
          }

          let sp_str = "";

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          let theme_obj = {};

          if (data[i].entity) {
            theme_obj = {
              name: data[i].entity.name,
              type: data[i].entity.type,
              description: data[i].description,
              is_favorite: data[i].entity.is_favorite,
              id: data[i].entity.id,
              slot_type:data[i].slot_type,
              room_number:data[i].room_number,
            };
          } else {
            theme_obj = { ...data[i] };
          }

          dates[dateIndex].sections[sectIndex].slots.push({
            ...theme_obj,
            time: time,
            sp_str: sp_str,
          });

          console.log("DDD", i, dates);

          if (dates[0].sections.length == 2) t_class = "col-md-6";
          if (dates[0].sections.length > 2) t_class = "col-md-4";
          if (dates[0].sections.length > 3) t_class = "col-md-3";
          if (dates[0].sections.length > 4) t_class = "col-md-2";
          //if (dates[0].sections.length > 6) t_class = 'col-md-1';
        }
      } else {
        console.log("ERR", i);
      }

      console.log("DDDD", i, dates);
    }

    console.log("DD5", dates);
    console.log(Object.values(speakers_dict));
    this.setState({ speakers: Object.values(speakers_dict) });

    console.log(dates);

    this.setState({
      scheduleData: dates,
      favChedule,
      table_class: t_class,
      oneDaySchedule: { ...dates[0] },
    });
    this.clickDate(this.state.curDateIndex, groupType, [...dates]);
  };

  getSchedule = (id) => {
    let self = this;

    userService.getWrapper(
      `api/schedule/${id}`,
      (data) => {
        if (data.status == 200) {
          self.setSchedule([...data.data.lessons]);
          self.setState({ lessons: [...data.data.lessons] });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  clickToList = () => {
    history.push("/events");
  };

  clickGroup = () => {
    const { lessons, groupBySections } = this.state;

    const newGroup = !groupBySections;

    this.setState({ groupBySections: newGroup });
    this.setSchedule(lessons, newGroup);
  };

  storeChange = (e) => {};

  clickDate = (ind, groupType, schedule) => {
    if (ind >= 0) {
      let t_class = "col-md-12";
      let col_count = 1;

      if (groupType) {
        if (schedule[ind] && schedule[ind].sections)
          col_count = schedule[ind].sections.length;
      } else if (schedule[ind] && schedule[ind].rooms)
        col_count = schedule[ind].rooms.length;
      if (col_count == 2) t_class = "col-md-6";
      if (col_count > 2) t_class = "col-md-4";
      if (col_count > 3) t_class = "col-md-3";
      if (col_count > 4) t_class = "col-md-2";
      //if (col_count > 6) t_class = 'col-md-1';

      this.setState({
        oneDaySchedule: { ...schedule[ind] },
        curDateIndex: ind,
        table_class: t_class,
      });
    } else
      this.setState({
        oneDaySchedule: { rooms: [] },
        curDateIndex: ind,
        table_class: "col-md-12",
      });
  };

  delImage = (tp) => {
    this.uploadFile(null, tp);
  };

  clickTicketMenu = () => {
    const { isPublic, id, hasTicket, ticketsInfo } = this.state;

    if (hasTicket) {
      /*
      console.log(ticketsInfo);
      if (ticketsInfo[0] && ticketsInfo[0].id)
          this.getTicketQR(ticketsInfo[0].id);
        */
    } else if (isPublic) {
      let token = getStorage("token");
      if (token) {
        history.push("/conf_view/" + id);
      } else {
        localStorage.setItem("navic_redirect", "/conf_view/" + id);
        history.push("/login");
      }
    } else this.setState({ openTicket: true });
  };

  onSave = (tp) => {};

  onTextChange = (e, tp) => {};

  clickStar = (e, item, val) => {
    e.stopPropagation();
    const self = this;

    const url1 = "api/lesson/" + item.id + "/favorite";

    if (val == 1) {
      userService.postWrapper(
        url1,
        {},
        function (data) {
          self.getSchedule(self.state.id);
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, loadingTickets: false });
          }
        }
      );
    } else {
      userService.delWrapper(
        url1,
        function (data) {
          self.getSchedule(self.state.id);
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, loadingTickets: false });
          }
        }
      );
    }
  };

  closeUserInfoDialog = (val) => {
    console.log(val);
    this.setState({ showInfoDialog: false });

    let self = this;

    if (val == null) return;

    userService.postWrapper(
      "api/anketa",
      val,
      function (data) {
        console.log(data);
        //self.getSchedule(self.state.id);
        window.location.href = self.state.ticketRedirect;
      },
      function (error) {
        if (error != "") {
          self.setState({ error: error });
        }
      }
    );
  };

  saveSpeakerOrder = () => {
    this.setState({ editSpeakerOrder: false });

    let self = this;

    const { speakers, id } = this.state;

    let post_data = speakers.map(function (item) {
      return {
        entity_type: "speaker",
        entity_id: item.id,
        order: item.order,
      };
    });

    userService.postWrapper(
      "conference/" + id + "/update-entity-settings",
      post_data,
      function (data) {
        console.log(data);
      },
      function (error) {
        if (error != "") {
          self.setState({ error: error });
        }
      }
    );
  };

  clickContacts = () => {
    const { classes, dispatch } = this.props;
    const { contacts } = this.state;

    if (contacts) {
      //dispatch(userActions.set_error(contacts, 'info'));
      //window.open(`mailto:${contacts}`);
      this.setState({ contactInfoOpen: true });
    }
  };

  handleStop = (result) => {
    let { speakers } = this.state;

    if (!result.destination) return;

    let arr = [...speakers];

    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    console.log(arr);
    let arrLen = arr.length;

    arr = arr.map(function (item, index) {
      return { ...item, order: arrLen - index };
    });

    console.log(arr);
    this.setState({ speakers: arr });

    //Updating the list
    //setDragDropList(arr);
  };

  renderSlots = (slots) => {
    const self = this;

    const { isPublic, format, groupBySections } = this.state;

    return slots.map((slotItem, slotIndex) => (
      <li
        style={{ cursor: "pointer" }}
        className="item"
        key={slotIndex}
        onClick={() =>
          self.setState({ slotData: { ...slotItem }, slotInfoOpen: true })
        }
      >
        <div className="time">
          <div>{slotItem.time ? slotItem.time : "??:??"}</div>

          {format == "hybrid" && (
            <div
              className="title-theme"
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {slotItem.type == "online" && (
                <div title="онлайн формат">
                  <img src={onlineLogo} />
                </div>
              )}
              {slotItem.type == "offline" && (
                <div title="оффлайн формат">
                  <img src={offlineLogo} />
                </div>
              )}
              {slotItem.type == "hybrid" && (
                <div title="онлайн+оффлайн формат">
                  <img src={onlineLogo} />
                </div>
              )}
              {slotItem.type == "hybrid" && (
                <div title="онлайн+оффлайн формат" style={{ marginLeft: 3 }}>
                  <img src={offlineLogo} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="theme">
          <div className="title-theme" style={{ fontSize: 16 }}>
            {replaceName(slotItem.name)}
          </div>
          <div className="desc-theme" style={{ fontSize: 16, marginTop: 10 }}>
            {slotItem.sp_str}
          </div>
          {groupBySections && slotItem.type != "online" && (
            <div className="desc-theme" style={{ marginTop: 10, fontSize: 16 }}>
              Зал: {slotItem.room_number ? slotItem.room_number : "уточняется"}
            </div>
          )}
        </div>
        {!isPublic && slotItem.is_favorite == false && (
          <div
            className="favorite"
            onClick={(e) => self.clickStar(e, slotItem, 1)}
          >
            <StarNotActive />
          </div>
        )}

        {!isPublic && slotItem.is_favorite == true && (
          <div
            className="favorite active"
            onClick={(e) => self.clickStar(e, slotItem, 0)}
          >
            <StarActive />
          </div>
        )}
      </li>
    ));
  };

  render() {
    const self = this;
    const {
      id,
      isLoading,
      image,
      comment,
      name,
      start_time,
      end_time,
      addr,
      format,
      navigation_building_description,
      navigation_description,
      map_description,
      map_image,
      map_link,
      navigation_building_image,
      navigation_image,
      hasTicket,
      sponsors,
      scheduleData,
      oneDaySchedule,
      curDateIndex,
      table_class,
      wifi,
      tickets,
      openTicket,
      slotData,
      slotInfoOpen,
      showInfoDialog,
      needFields,
      favChedule,
      groupBySections,
      isUrl,
      isPublic,
      isErr,
      contactInfoOpen,
      contacts,
      urlt,
      ticket_text,
      lessons,
      oneColumn,
      speakerData,
      speakerInfoOpen,
      sponsorData,
      sponsorInfoOpen,
      isNoMenu,
      speakers,
      isMobile,
      speakersOpen,
      openQR,
      qrData,
      editSpeakerOrder,
    } = this.state;

    const { classes, user, schedule } = this.props;

    return (
      <div className="layout" style={{ width: "100%", overflowX: "hidden" }}>
        {!id && <ConferenceNotSelected page={"client_view"} />}
        {isErr && <ConferenceNotSelected page={"client_view"} />}
        {id > 0 && !isErr && (
          <div>
            {isNoMenu && (
              <button
                style={{ marginLeft: 30, marginTop: 20 }}
                className="btn-gradient"
                onClick={this.clickToList}
              >
                {to_list_text}
              </button>
            )}
            {isMobile && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "95%", height: "auto" }}
                  src={image ? serverUrl + image : confLogo}
                  alt=""
                />
              </div>
            )}
            <section className="member-top">
              <div className="conf-detail conf-detail-member">
                {!isMobile && (
                  <div className="conf-img">
                    <img src={image ? serverUrl + image : confLogo} alt="" />
                  </div>
                )}
                <div className="conf-info">
                  <div className="title h1">{name}</div>
                  <div className="meta">
                    <div className="date">
                      <span>
                        {confService.getConfDateTimeStr(start_time, end_time)}
                      </span>
                    </div>
                  </div>
                  <div className="meta">
                    <div className="address">
                      <span>{addr}</span>
                    </div>
                  </div>
                  <div className="meta">
                    <div className="date">
                      {format == "online" && "Онлайн мероприятие"}
                      {format == "offline" && "Оффлайн мероприятие"}
                      {format == "hybrid" && "Онлайн+оффлайн мероприятие"}
                    </div>
                  </div>

                  {isMobile && !isPublic && hasTicket && wifi && (
                    <div className="meta">
                      <div className="date">
                        <span>
                          Интернет на
                          <br /> площадке {": "} {wifi}
                        </span>
                      </div>
                    </div>
                  )}

                  {format != "offline" && (
                    <div className="meta">
                      <div className="date">
                        {isUrl && hasTicket && (
                          <a class="btn-icon" href={urlt} target="_blank">
                            <a>{urlt}</a>
                          </a>
                        )}

                        {isPublic && format != "offline" && (
                          <a class="btn-icon" onClick={self.redirectToConf}>
                            <a>Смотреть трансляцию</a>
                          </a>
                        )}

                        {isUrl && !hasTicket && !isPublic && (
                          <a class="btn-icon">
                            <span>
                              Ссылка будет доступна после покупки билета
                            </span>
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="btn-member">
                    {!hasTicket && (
                      <button
                        className="btn-gradient"
                        onClick={this.clickTicketMenu}
                      >
                        {ticket_text}
                      </button>
                    )}

                    {hasTicket && (
                      <div className="btn-disabled">Участие подтверждено</div>
                    )}

                    <button
                      className="btn-border"
                      onClick={this.clickContacts}
                      style={{ marginLeft: 5 }}
                    >
                      <span>Связаться с организаторами</span>
                    </button>
                  </div>
                </div>
                {!isMobile && !isPublic && hasTicket && wifi && (
                  <div className="conf-links">
                    <a className="btn-icon" style={{ marginBottom: 15 }}>
                      <img src={inetLogo} style={{ marginRight: 10 }} />
                      <span>
                        Интернет на
                        <br /> площадке {": "} {wifi}
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </section>

            {(self.props.isOrg ||
              (hasTicket && (comment || sponsors.length > 0))) && (
              <section className="description block-default">
                {comment && <div className="title">Описание мероприятия</div>}
                {comment && (
                  <p className="text_n" style={{ fontSize: 18 }}>
                    {comment || "Нет описания"}
                  </p>
                )}

                {speakers.length > 0 && (
                  <div className="partners" style={{ marginBottom: 20 }}>
                    <div
                      className="title-small"
                      style={{ margin: 0 }}
                      onClick={() =>
                        self.setState({ speakersOpen: !speakersOpen })
                      }
                    >
                      <span
                        style={{ fontSize: 20, textDecoration: "underline" }}
                      >
                        Спикеры
                      </span>
                      {speakersOpen ? (
                        <ExpandLess fontSize="large" />
                      ) : (
                        <ExpandMore fontSize="large" />
                      )}
                    </div>
                    <Collapse
                      in={speakersOpen}
                      timeout="auto"
                      style={{ marginTop: 10 }}
                    >
                      {editSpeakerOrder && (
                        <div>
                          <Button
                            style={{ marginBottom: 10 }}
                            variant="contained"
                            onClick={this.saveSpeakerOrder}
                          >
                            Сохранить
                          </Button>
                        </div>
                      )}
                      {editSpeakerOrder && (
                        <div>
                          <Button
                            style={{ marginBottom: 10 }}
                            variant="contained"
                            onClick={() => {
                              this.setState({ editSpeakerOrder: false });
                              this.getSchedule(this.state.id);
                            }}
                          >
                            Отмена
                          </Button>
                        </div>
                      )}

                      {editSpeakerOrder && (
                        <DragDropContext
                          onDragEnd={this.handleStop}
                          onDragStart={this.handleStart}
                        >
                          <Droppable droppableId="drag-drop-list">
                            {(provided, snapshot) => (
                              <div
                                className="drag-drop-list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {speakers
                                  .sort((a, b) => b.order - a.order)
                                  .map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.name}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="item-card"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            self.setState({
                                              speakerData: { ...item },
                                              speakerInfoOpen: true,
                                            })
                                          }
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <img
                                            style={{
                                              width: 120,
                                              height: 120,
                                              borderRadius: 60,
                                              marginBottom: 10,
                                            }}
                                            src={
                                              item.photo
                                                ? serverUrl +
                                                  item.photo.replace(
                                                    "public",
                                                    "storage"
                                                  )
                                                : personLogo
                                            }
                                            alt=""
                                          />
                                          <span style={{ fontSize: 18 }}>
                                            {item.name}
                                          </span>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      {self.props.isOrg && !editSpeakerOrder && (
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.setState({ editSpeakerOrder: true })
                          }
                        >
                          Изменить порядок
                        </Button>
                      )}
                      {!editSpeakerOrder && (
                        <ul>
                          {speakers
                            .sort((a, b) => b.order - a.order)
                            .map((item) => (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  self.setState({
                                    speakerData: { ...item },
                                    speakerInfoOpen: true,
                                  })
                                }
                              >
                                <img
                                  style={{
                                    width: 120,
                                    height: 120,
                                    borderRadius: 60,
                                    marginBottom: 10,
                                  }}
                                  src={
                                    item.photo
                                      ? serverUrl +
                                        item.photo.replace("public", "storage")
                                      : personLogo
                                  }
                                  alt=""
                                />
                                <span style={{ fontSize: 18 }}>
                                  {item.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      )}
                    </Collapse>
                  </div>
                )}

                {sponsors.length > 0 && (
                  <div className="partners">
                    <div className="title-small" style={{ margin: 0 }}>
                      Партнёры и спонсоры
                    </div>
                    <ul>
                      {sponsors.length > 0 &&
                        sponsors.map((item) => (
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              self.setState({
                                sponsorData: { ...item },
                                sponsorInfoOpen: true,
                              })
                            }
                          >
                            <img
                              style={{
                                width: 60,
                                height: 60,
                                borderRadius: 30,
                              }}
                              src={
                                item.logo
                                  ? serverUrl +
                                    item.logo.replace("public", "storage")
                                  : personLogo
                              }
                              alt=""
                            />
                            <span>{item.brandname}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </section>
            )}

            {hasTicket && lessons.length > 0 && (
              <section className="block-default">
                <div className="title">Программа</div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="tab-switcher"
                    style={{ marginBottom: isMobile ? 10 : 30 }}
                  >
                    <ul className="tabs-member">
                      {scheduleData.length > 0 &&
                        scheduleData.map((item, index) => (
                          <li
                            data-tab="27"
                            onClick={() =>
                              self.clickDate(
                                index,
                                groupBySections,
                                scheduleData
                              )
                            }
                            className={index == curDateIndex ? "active" : ""}
                          >
                            {item.title}
                          </li>
                        ))}

                      {!isPublic && (
                        <li
                          data-tab="favorite"
                          className={curDateIndex < 0 ? "active" : ""}
                          onClick={() => self.clickDate(-1)}
                        >
                          Мое избранное
                        </li>
                      )}
                    </ul>
                  </div>
                  {!isMobile && (
                    <FormGroup style={{ marginLeft: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={groupBySections}
                            onClick={this.clickGroup}
                          />
                        }
                        label="Группировать по секциям"
                      />
                    </FormGroup>
                  )}
                  {!isMobile && (
                    <FormGroup style={{ marginLeft: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={oneColumn}
                            onClick={() =>
                              this.setState({ oneColumn: !oneColumn })
                            }
                          />
                        }
                        label="В одну колонку"
                      />
                    </FormGroup>
                  )}
                </div>
                {isMobile && (
                  <FormGroup style={{ marginLeft: 20 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={groupBySections}
                          onClick={this.clickGroup}
                        />
                      }
                      label="Группировать по секциям"
                    />
                  </FormGroup>
                )}

                <div className="tabs-client-content">
                  <div
                    style={{ width: "100%" }}
                    className={oneColumn || isMobile ? "" : "row"}
                  >
                    {groupBySections == false &&
                      oneDaySchedule.rooms &&
                      oneDaySchedule.rooms.map((roomItem, roomInd) => (
                        <div
                          className={oneColumn ? "md-12" : table_class}
                          style={{
                            paddingTop: oneColumn || isMobile ? 20 : 0,
                            borderTop:
                              (oneColumn || isMobile) && roomInd > 0
                                ? "solid 1px"
                                : "",
                          }}
                        >
                          <div className="title-small">
                            <span style={{ fontWeight: 600 }}>
                              {oneColumn || isMobile ? "Помещение: " : ""}
                            </span>
                            {roomItem.room_number
                              ? roomItem.room_number
                              : nodata_text}
                          </div>
                          <ul className="list-schedule">
                            {roomItem.sections &&
                              roomItem.sections.map((sectItem, sectIndex) => (
                                <li>
                                  <ul>
                                    {sectItem.name != NO_SECTION && (
                                      <li
                                        className="item"
                                        style={{ backgroundColor: "#ffffdd" }}
                                      >
                                        <div className="time">Секция</div>
                                        <div className="theme">
                                          <div className="title-theme">
                                            {sectItem.name}
                                          </div>
                                        </div>
                                      </li>
                                    )}

                                    {self.renderSlots(sectItem.slots)}
                                  </ul>
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}

                    {groupBySections &&
                      oneDaySchedule.sections &&
                      oneDaySchedule.sections.map((sItem, sInd) => (
                        <div
                          className={
                            oneColumn || isMobile ? "md-12" : table_class
                          }
                          style={{
                            paddingTop: oneColumn || isMobile ? 20 : 0,
                            borderTop:
                              (oneColumn || isMobile) && sInd > 0
                                ? "solid 1px"
                                : "",
                          }}
                        >
                          {(oneDaySchedule.sections.length > 1 ||
                            sItem.name) && (
                            <div className="title-small">
                              <span style={{ fontWeight: 600 }}>
                                {oneColumn || isMobile ? "Секция: " : ""}
                              </span>
                              {sItem.name
                                ? replaceName(sItem.name)
                                : nodata_text}
                            </div>
                          )}
                          <ul className="list-schedule">
                            {sItem.slots &&
                              sItem.slots.map((sectItem, sectIndex) => (
                                <li style={{ marginBottom: 10 }}>
                                  {self.renderSlots([sectItem])}
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}

                    {curDateIndex < 0 && favChedule.length == 0 && (
                      <ul>
                        <li>
                          <div>В избранном ничего нет</div>
                        </li>
                      </ul>
                    )}
                    {curDateIndex < 0 && favChedule.length > 0 && (
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-12">
                          <ul className="list-schedule">
                            {favChedule.map((item) => (
                              <li
                                className="item"
                                onClick={() =>
                                  self.setState({
                                    slotData: { ...item },
                                    slotInfoOpen: true,
                                  })
                                }
                              >
                                <div className="time" style={{ width: 130 }}>
                                  <div>{item.ftime}</div>
                                  <div style={{ paddingTop: 5 }}>
                                    {item.fdate}
                                  </div>
                                </div>

                                <div className="theme">
                                  <div className="title-theme">
                                    {replaceName(item.name)},{" "}
                                    {item.room_number
                                      ? item.room_number
                                      : nodata_text}{" "}
                                  </div>
                                  <div className="desc-theme">
                                    {item.sp_str}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}

            {hasTicket && format != "online" && self.isNavigateEnabled() && (
              <section className="block-default navigation-desc">
                <div className="title">Навигация</div>

                {(navigation_building_description ||
                  navigation_building_image) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <img
                        src={
                          navigation_building_image
                            ? serverUrl + navigation_building_image
                            : ""
                        }
                        style={{ maxHeight: 500 }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="title-small">Карта выставки</div>
                      <p className="text_n">
                        {navigation_building_description}
                      </p>
                    </div>
                  </div>
                )}

                {(navigation_description || navigation_image) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <img
                        src={
                          navigation_image ? serverUrl + navigation_image : ""
                        }
                        style={{ maxHeight: 500 }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="title-small">Карта площадки</div>
                      <p className="text_n">{navigation_description}</p>
                    </div>
                  </div>
                )}
                {!isMobile && (map_description || map_link) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <div className="title-small">Как добраться?</div>
                      <p className="text_n">
                        {" "}
                        <div
                          style={{ fontSize: 14 }}
                          dangerouslySetInnerHTML={{ __html: map_description }}
                        />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        style={{
                          height: window.innerWidth * 0.4,
                          outline: "1px solid #888",
                        }}
                      >
                        {map_link && (
                          <iframe
                            src={map_link}
                            width="100%"
                            height="100%"
                            frameborder="1"
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isMobile && map_description && (
                  <div style={{ width: "100%" }}>
                    <div className="title-small">Как добраться?</div>
                    <p className="text_n">
                      {" "}
                      <div
                        style={{ fontSize: 14 }}
                        dangerouslySetInnerHTML={{ __html: map_description }}
                      />
                    </p>
                  </div>
                )}
                {isMobile && map_link && (
                  <div style={{ width: "100%" }}>
                    <div style={{ outline: "1px solid #888" }}>
                      {map_link && (
                        <iframe
                          src={map_link}
                          width="100%"
                          height={window.screen.width * 0.9}
                          frameborder="1"
                        ></iframe>
                      )}
                    </div>
                  </div>
                )}
              </section>
            )}
          </div>
        )}
        <TicketDialog
          handleClose={self.closeTicketDialog}
          tickets={tickets}
          dialogOpen={openTicket}
        />
        <QRDialog
          handleClose={() => self.setState({ openQR: false, qrData: null })}
          qrData={qrData}
          dialogOpen={openQR}
        />
        <UserInfoDialog
          handleClose={self.closeUserInfoDialog}
          flds={needFields}
          dialogOpen={showInfoDialog}
        />

        <SlotInfo
          handleClose={() => self.setState({ slotInfoOpen: false })}
          data={slotData}
          open={slotInfoOpen}
          isPublic={isPublic ? true : hasTicket ? false : true}
          redirectToConf={self.redirectToConf}
          sendQ={self.sendQ}
          hasTicket={hasTicket}
        />
        <SpeakerInfo
          handleClose={() => self.setState({ speakerInfoOpen: false })}
          data={speakerData}
          open={speakerInfoOpen}
        />
        <SponsorInfo
          handleClose={() => self.setState({ sponsorInfoOpen: false })}
          data={sponsorData}
          open={sponsorInfoOpen}
        />
        <ContactInfo
          handleClose={() => self.setState({ contactInfoOpen: false })}
          data={contacts}
          open={contactInfoOpen}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
    schedule,
  };
}

const connectedClientConfPage = connect(mapStateToProps)(ClientConfPage);
export { connectedClientConfPage as ClientConfPage };
