import React from "react";
import TextField from "@mui/material/TextField";
import i18next from "i18next";
import { initLanguage } from "../_helpers";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import { userService } from "../_services";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { responseErrorToText } from "../_helpers";
import { Snack } from "../_components";
import { NoAuthPage } from "./NoAuthPage";

export default class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
    this.params = new URLSearchParams(this.props.location.search);
    initLanguage();
  }

  resetPassword = (e) => {
    e.preventDefault();
    store.dispatch(userActions.loading(true));
    userService.postWrapper(
      "api/password/reset",
      {
        email: this.params.get("email"),
        token: this.params.get("token"),
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      },
      (data) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: i18next.t("Password has been changed."),
          alertShow: true,
          errType: "success",
        });
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: responseErrorToText(error),
          alertShow: true,
          errType: "error",
        });
      }
    );
  };

  render() {
    const form = (
      <form onSubmit={this.resetPassword}>
        <h3>{i18next.t("Reset password for ") + this.params.get("email")}</h3>
        <div>
          <TextField
            label={i18next.t("Password")}
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label={i18next.t("Password confirmation")}
            type="password"
            onChange={(e) =>
              this.setState({ password_confirmation: e.target.value })
            }
            variant="standard"
          />
        </div>
        <CustomizedButton
          type="submit"
          title={i18next.t("Refresh password")}
          prim
          className="form-button"
        />
      </form>
    );
    const snack = (
      <Snack
        open={this.state.alertShow}
        variant={this.state.errType}
        text={this.state.snackText}
        onClose={() => this.setState({ alertShow: false })}
      />
    );
    return <NoAuthPage form={form} snack={snack} />;
  }
}
