import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../css/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { serverUrl } from "../_constants";

export default function TeamItem(props) {
	
	console.log(props);
	
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div className="tbl__item tbl__item--city">
            <span className="profile__name">
              {props.data ? props.data.user_name : ""}
            </span>
          
        </div>
		<div className="tbl__item tbl__item--city">
          <span>{props.data ? props.data.conf_name : ""}</span>
        </div>
        

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.ticket_name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>
            {props.data ? props.data.updated_at.replace(".000000Z", "") : ""}
          </span>
        </div>

      </div>
    </div>
  );
}
