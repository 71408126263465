import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./style.css";

import { store } from "./_helpers";
import { App } from "./App";

import "./i18n";


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
