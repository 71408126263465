import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormatIcons from '../_components/FormatIcons';
import i18next from "i18next";
import "../css/style.css";
import onlineLogo from "../img/online.png";
import offlineLogo from "../img/offline.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const no_text = "уточняется";
const no_url_text =
  "Смотреть трансляцию";

export default function BldInfo(props) {
  const { handleClose, data,id, open} = props;

    console.log(data,id);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Список залов:
          </Typography>
          
          {data.filter(e=>e.building_id == id).map(e=> (<Typography id="transition-modal-description" sx={{ mt: 2 }} style={{whiteSpace: 'pre-wrap' }}>
             {e.number}
          </Typography>))}
          
          
        </Box>
      </Fade>
    </Modal>
  );
}
