import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import "../css/style.css";
import { serverUrl } from "../_constants";

export default function MemberDialog(props) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComm] = React.useState("");
  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    label,
    title,
    dialogOpen,
    dName,
    dMail,
    dComm,
    id,
    image,
    uploadImage,
    imageStatus,
  } = props;

  React.useEffect(() => {
    if (count == 0 && dialogOpen) {
      setName(dName);
      setEmail(dMail);
      setComm(dComm);

      setCount(1);
    }
  });

  function saveData(e) {
    e.preventDefault();

    if (name != "") {
      handleClose({ name, email, comment });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);

    if (acceptedFiles.length > 0) {
      // this.setState({ selectedFile: acceptedFiles[0],loadedProgress: 0, uploadState:0});

      props.uploadImage(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent>
        <div style={{display:'flex', justifyContent: 'center'}}>
          <Dropzone onDrop={onDrop} class="visually--hidden">
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  width: 250,
                  height: 250,
                  background: "#eee",
                  borderRadius: 5,
                  border:"1px solid #000"
                }}
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />

                {image && imageStatus == 0 && (
                  <img
                    src={image ? serverUrl + image : ""}
                    alt=""
                    className="object-fit is--cover"
                  />
                )}
                {imageStatus == 0 &&
                  (isDragActive ? (
                    <p
                      style={{
                        fontSize: 12,
                        position: "relative",
                        top: 110,
                        left: 15,
                      }}
                    >
                      Загрузить файл
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: 12,
                        position: "relative",
                        top: image ?-135:100,
                        left: 15,
                        width: 220,
                        background: "#eee",
                        padding: 10,
                        borderRadius: 5,
                      }}
                    >
                      Перетащите файл сюда или нажмите для загрузки файла
                    </p>
                  ))}
                {imageStatus == 1 && (
                  <p
                    style={{
                      fontSize: 12,
                      position: "relative",
                      top: 110,
                     
                    }}
                  >
                    Файл загружен
                  </p>
                )}
              </div>
            )}
          </Dropzone>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Название бренда"
            fullWidth
            variant="standard"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="comment"
            label="Комментарий"
            fullWidth
            variant="standard"
            value={comment}
            multiline
            onChange={(e) => {
              setComm(e.target.value);
              setCount(count + 1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
