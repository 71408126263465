import { userService } from "./user.service";
import { store } from "../_helpers";
import { appActions, userActions, scheduleActions } from "../_actions";

export const confService = {
  confInfo,
  confList,
  confListStudents,
  ticketsInfo,
  validateEmail,
  validatePassword,
  validateUrl,
  validateLogin,
  confListParticipants,
  getConfDateTimeStr,
};

function validateEmail(email) {
  // var re = /\S+@\S+\.\S+/;
  // ^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$
  let re = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*/;
  return re.test(email);
}

function validatePhone(val) {
  let re = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  return re.test(val);
}

function validateLogin(pwd) {
  let re = /^[a-zA-Z0-9-_]+$/;
  return re.test(pwd);
}

function validatePassword(pwd) {
  let re = /^[a-zA-Z0-9-_!@#$%^&]+$/;
  return re.test(pwd);
}

function validateUrl(url) {
  let re = /https?\:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

  return re.test(url);
}

function getConfDateTimeStr(start_src,end_src)
{
    if (!start_src)
        return '';
    
    if (!end_src)
        return '';
    
    let start_date = start_src.substr(0, 10).split('-').reverse().join('.');
    let end_date = end_src.substr(0, 10).split('-').reverse().join('.');
    let start_time = start_src.substr(11, 5);
    let end_time = end_src.substr(11, 5);
    
    if (start_date != end_date)
    {
        return start_date+" "+start_time+" - "+end_date+" "+end_time;
    }
    
    return start_date+" "+start_time+"-"+end_time;
    
}

function confInfo(id, cbD, cbE) {
    
  userService.getWrapper(
    `api/conference/${id}`,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function confList(for_student, cbD, cbE) {
  let url = "api/conference";
  if (for_student) url += "/all";

  userService.getWrapper(
    url,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function checkUserTicketExists(data, user_id, ticket_id) {
  for (let i = 0; i < data.length; i++)
    if (data[i].user_id == user_id && data[i].ticket_id == ticket_id) return i;

  return -1;
}

function confListStudents(cbD, cbE) {
  const confs_data = [];

  confList(
    false,
    function (data) {
      
      let urls = [];
      let confs_data = [];
      let partip_data = [];

      if (data.data.length > 0) {
        confs_data = [...data.data];

        let tmp_partips = [];

        for (let i = 0; i < data.data.length; i++)
          urls.push(`api/conference/${data.data[i].id}/students`);

        userService.getAsync(
          urls,
          function (data) {
            

            for (let i = 0; i < data.length; i++) {
              tmp_partips = [];


              for (let j = 0; j < data[i].data.length; j++) {
                const posId = checkUserTicketExists(
                  [...tmp_partips],
                  data[i].data[j].user_id,
                  data[i].data[j].ticket_type_id
                );
                if (posId >= 0) tmp_partips[posId].count += 1;
                else {
					
					let tmpObj = {
                    conf_id: confs_data[i].id,
                    conf_name: confs_data[i].name,
                    user_id: data[i].data[j].user.id,
                    user_name: data[i].data[j].user.name,
                    ticket_id: data[i].data[j].ticket_type.id,
                    ticket_name: data[i].data[j].ticket_type.name,
                    updated_at: data[i].data[j].updated_at,
                    count: 1,
                  };
				  
				  let anketa = data[i].data[j].user.anketa;
				  
				  if (anketa)
				  {
					  
					  tmpObj.user_name = anketa.lastname+" "+ anketa.firstname+" "+anketa.patronymic;
				  }
					
                  tmp_partips.push(tmpObj);
                }
              }

              partip_data = partip_data.concat(tmp_partips);
            }

            cbD(partip_data);
          },
          function (err) {
            console.log(err);
          }
        );
      } else {
        cbD([]);
      }
    },
    function (err) {
      // cbE(err);
      store.dispatch(userActions.set_error(err));
    }
  );
}

function confListParticipants(forStudent = false) {
  const confs_data = [];

  confList(
    forStudent,
    function (data) {
      let urls = [];
      let confs_data = [];
      let partip_data = [];

      if (data.data.length > 0) {
        confs_data = [...data.data];
        store.dispatch(userActions.set_conf_list(confs_data));
        let tmp_partips = [];

        for (let i = 0; i < data.data.length; i++)
          urls.push(`api/conference/${data.data[i].id}`);

        userService.getAsync(
          urls,
          function (data) {
            for (let i = 0; i < data.length; i++) {
              confs_data[i].participants_count =
                data[i].data.participants_count;
              confs_data[i].sponsors_count = data[i].data.sponsors_count;
            }


            const state = store.getState();

            store.dispatch(userActions.set_conf_list(confs_data));
          },
          function (err) {
            store.dispatch(userActions.set_error(err));
          }
        );
      } else {
        store.dispatch(userActions.set_conf_list([]));
      }
    },
    function (err) {
      store.dispatch(userActions.set_error(err));
    }
  );
}

function ticketsInfo(id, cbD, cbE) {
  userService.getWrapper(
    `api/ticket-type?conference_id=${id}`,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}
