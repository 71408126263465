import React from "react";
import PropTypes from "prop-types";
import "../css/style.css";
import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userService, confService } from "../_services";
import {ConferenceNotSelected} from "../_components/ConferenceNotSelected";
import { userActions, appActions } from "../_actions";
import i18next from "i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { SendButton, TextFieldCell, Snack } from "../_components";
import { history } from "../_helpers";
import { serverUrl } from "../_constants";
import playLogo from "../img/play.png";
import inetLogo from "../img/inet.png";
import confLogo from "../img/conf_logo.png";

const getDate = (time) => {
  if (!time) return "не определено";

  const time1 = time.substr(0, 10);

  return time1.split("-").reverse().join("-");

  return time1;
};


class ConfNavPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, addr: "" };
    store.subscribe(this.storeChange);
  }

  loadConferenceData = selectedConf => {
      
      
    this.setState({
      name: selectedConf.name,
      format: selectedConf.type,
      comment: selectedConf.description,
      streamUrl: selectedConf.navigation_description,
      id: selectedConf.id,
      image: selectedConf.image,
      building_id: selectedConf.building_id,
      start_time: selectedConf.start_time,
      end_time: selectedConf.end_time,
      selectedBldId: selectedConf.building_id,
      wifi: selectedConf.wifi,
      map_description: selectedConf.map_description,
      map_image: selectedConf.map_image,
      map_link: selectedConf.map_link,
      navigation_building_description:
        selectedConf.navigation_building_description,
      navigation_building_image: selectedConf.navigation_building_image,
      navigation_description: selectedConf.navigation_description,
      navigation_image: selectedConf.navigation_image,
    });

    this.getBuildings();
    this.getConfInfo(selectedConf.id);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedConf } = this.props.user;

    if (selectedConf != prevProps.user.selectedConf) {
      this.loadConferenceData(selectedConf);
    }
  }

  componentDidMount() {
    console.log(this.props.user);

    const { selectedConf } = this.props.user;

    if (selectedConf && selectedConf.id && selectedConf.id > 0) {
      this.loadConferenceData(selectedConf);
    }
  }
  
  setYandexData = (e) => {
      
  } 

  getConfInfo = (id) => {
    const self = this;

    confService.confInfo(
      id,
      (data) => {
        if (data.status == 200) {
          self.setState({
            map_description: data.data.map_description,
            map_image: data.data.map_image,
            map_link:data.data.map_link,
            navigation_building_description:
              data.data.navigation_building_description,
            navigation_building_image: data.data.navigation_building_image,
            navigation_description: data.data.navigation_description,
            navigation_image: data.data.navigation_image,
          });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };

  getBuildings = () => {
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        let addr = "";
        let blds = [];
        console.log(data);
        if (data.data) {
          blds = [...data.data];

          for (let i = 0; i < blds.length; i++) {
            if (blds[i].id == self.state.building_id) {
              addr = blds[i].address;
              break;
            }
          }

          self.setState({ blds, addr });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };

  storeChange = (e) => {};

  delImage = (tp) => {
    this.uploadFile(null, tp);
  };

  onSave = (tp) => {
    const self = this;
    const {
      id,
      navigation_description,
      navigation_building_description,
      map_description,
      map_link,
    } = this.state;

    // api/conference/1?
    const post_data = {};

    if (tp == 1) { 
    post_data.navigation_description = navigation_description;
    
    }

    if (tp == 0) {
        post_data.map_description = map_description;
        post_data.map_link = map_link;
    }

    if (tp == 2)
      post_data.navigation_building_description = navigation_building_description;

    userService.patchWrapperBody(
      `api/conference/${id}`,
      post_data,
      (data) => {
        console.log(data);
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };
  
  onMapLinkChange = (e) => {
      
    let sc_text = e.target.value;
    
    if (sc_text.indexOf('<iframe')==0)
    {
        sc_text = sc_text.substr(13);
        sc_text = sc_text.split('"')[0];
        this.setState({map_link:sc_text});
    }
    else
        this.setState({map_link:''});
  };

  onTextChange = (e, tp) => {
    if (tp == 1) this.setState({ navigation_description: e.target.value });

    if (tp == 0) this.setState({ map_description: e.target.value });

    if (tp == 2)
      this.setState({ navigation_building_description: e.target.value });
  };

  onDrop = (acceptedFiles, rejectedFiles, tp) => {
    console.log(acceptedFiles);

    if (acceptedFiles.length > 0) {
      this.uploadFile(acceptedFiles[0], tp);
    }
  };

  uploadFile = (file, tp) => {
    const data_upload = new FormData();

    if (tp == 0) data_upload.append("navigation_image", file);

    if (tp == 1) data_upload.append("map_image", file);

    if (tp == 2) data_upload.append("navigation_building_image", file);

    const self = this;

    userService.uploadFile(
      `api/conference/${this.state.id}/upload`,
      data_upload,
      (data) => {
        if (tp == 0 && data && data.data)
          self.setState({ navigation_image: data.data.navigation_image });
        if (tp == 1 && data && data.data)
          self.setState({ map_image: data.data.map_image });
        if (tp == 2 && data && data.data)
          self.setState({
            navigation_building_image: data.data.navigation_building_image,
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    console.log("qqq");
    console.log(this.state);
    console.log(this.props.user);

    const self = this;
    const {
      id,
      isLoading,
      image,
      name,
      start_time,
      end_time,
      addr,
      navigation_building_description,
      navigation_description,
      map_description,
      map_image,
      map_link,
      navigation_building_image,
      navigation_image,
      wifi,
    } = this.state;

    const { classes, user } = this.props;

    return (
      <div className="layout">
        {!id && (
          <ConferenceNotSelected page={"nav"}/>
        )}
        {id > 0 && (
          <div>
            <div className="tabs-top">
              <ul className="tabs-setting-conf-contents">
                <li>
                  <div className="conf-detail">
                    <div className="conf-img">
                      <img src={image ? serverUrl + image : confLogo} alt="" />
                    </div>
                    <div className="conf-info">
                      <div className="title h1">{name}</div>
                      <div className="meta">
                        <div className="date">
                          <div className="day">
                            <span>
                               {confService.getConfDateTimeStr(start_time,end_time)}
                            </span>
                          </div>
                        </div>
                        <div className="address">
                          <span>{addr}</span>
                        </div>
                      </div>
                    </div>
                    <div className="conf-links"></div>
                  </div>
                </li>
              </ul>
            </div>

            <div>
              <div className="header-block">
                <div className="title">Настройки навигации</div>
              </div>
              
              
              <div className="content-block">
                <div className="settings-block">
                  <div className="item">
                    <div className="title">Маршрут до площадки</div>
                    <div className="edit-btns">
                      <DeleteIcon onClick={() => this.setState({map_link:''})} />
                    </div>
                    
                    <div className="sub-title">Скрипт карты, iframe</div>
                    <input
                      name=""
                      placeholder="Код карты"
                      onChange={(e) => this.onMapLinkChange(e)}
                      value={map_link?'<iframe src="'+map_link+'" width="100%" height="100%" frameborder="0"></iframe>':''}
                    />
                    <div style={{height:400}}>
                    <iframe src={map_link} width="100%" height="100%" frameborder="1" ></iframe>    
                    </div>  

                    <div className="sub-title">Описание</div>

                    <textarea
                      name=""
                      placeholder="Текст"
                      onChange={(e) => this.onTextChange(e, 0)}
                      value={map_description}
                    ></textarea>

                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(0)}
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                  <div className="item">
                    <div className="title">Карта площадки</div>
                    <div className="edit-btns">
                      <DeleteIcon onClick={() => this.delImage(1)} />
                    </div>
                    <div style={{height:482 }}>
                    <Dropzone onDrop={(e1, e2) => this.onDrop(e1, e2, 0)}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          class="file-lable"
                          {...getRootProps()}
                          className={classNames("dropzone", {
                            "dropzone--isActive": isDragActive,
                          })}
                        >
                          <input {...getInputProps()} />
                          <div class="file-label">
                            <div class="file-label_info">
                              <span>Выбрать файл</span>
                            </div>
                            {map_image && (
                              <img
                                src={navigation_image ? serverUrl + navigation_image : ""}
                                alt=""
                              />
                            )}
                            <span class="desc-file">
                              Или перетащите изображение
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    </div>
               
                    <div className="sub-title">Описание</div>

                    <textarea
                      name=""
                      placeholder="Текст"
                      onChange={(e) => this.onTextChange(e, 1)}
                      value={navigation_description}
                    ></textarea>

                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(1)}
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                  
                  <div className="item">
                    <div className="title">Карта выставки</div>
                    <div className="edit-btns">
                      <DeleteIcon onClick={() => this.delImage(2)} />
                    </div>
                    <div style={{height:482}}>
                    <Dropzone onDrop={(e1, e2) => this.onDrop(e1, e2, 2)}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          class="file-lable"
                          {...getRootProps()}
                          className={classNames("dropzone", {
                            "dropzone--isActive": isDragActive,
                          })}
                        >
                          <input {...getInputProps()} />
                          <div class="file-label">
                            <div class="file-label_info">
                              <span>Выбрать файл</span>
                            </div>
                            {navigation_building_image && (
                              <img
                                src={
                                  navigation_building_image
                                    ? serverUrl + navigation_building_image
                                    : ""
                                }
                                alt=""
                              />
                            )}
                            <span class="desc-file">
                              Или перетащите изображение
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    </div>
                    <div className="sub-title">Описание</div>

                    <textarea
                      name="nav_b"
                      placeholder="Текст"
                      onChange={(e) => this.onTextChange(e, 2)}
                      value={navigation_building_description}
                    ></textarea>
                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(2)}
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedConfNavPage = connect(mapStateToProps)(ConfNavPage);
export { connectedConfNavPage as ConfNavPage };
