import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import i18next from "i18next";
import { initLanguage } from "../_helpers";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import { userService } from "../_services";
import { TextField } from "@mui/material";
import { responseErrorToText } from "../_helpers";
import { Snack } from "../_components";
import { NoAuthPage } from "./NoAuthPage";
import { history } from "../_helpers";

export default class ModerationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
    initLanguage();
  }

  render() {
    
    return (<div style={{ height: '100vh', display: 'flex',
    flexDirection: 'column',
   alignItems: 'center' ,
   justifyContent: 'center'}}> 
    <div style={{marginLeft: 30,marginRight:30, marginBottom: 20, fontSize:30}}>Доступ к мероприятию появится после подтверждения вашего участия организаторами</div>
    <CustomizedButton
          title={"Вернуться"}
          prim
          className="form-button"
          onClick={()=>history.push('/')}
        /></div>);
  }
}
