import React from "react";
import { Button, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

import axios from "axios";
import { SendButton, TextFieldCell } from "../_components";
import { history } from "../_helpers";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { serverUrl, agrUrl } from "../_constants";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}


function getStorage(name)
{
	return localStorage.getItem(name);
}

function setStorage(name,val)
{
	localStorage.setItem(name,val);
}

function setCookie(name, value, options) {
  options = options || {};
  let { expires } = options;
  if (typeof expires === "number" && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }

  if (expires && expires.toUTCString) options.expires = expires.toUTCString();

  value = encodeURIComponent(value);
  let updatedCookie = `${name}=${value}`;
  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

export default class AgrPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeOk: false,
      isLoading: false,
    };
  }

  componentDidMount() {}

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open(agrUrl);
  };

  handleSubmit = (e) => {
    const token = getStorage("token_tmp");

    const { agreeOk, isLoading } = this.state;

    const self = this;

    if (agreeOk) {
      this.setState({ isLoading: true });

      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios.defaults.withCredentials = true;
      axios.defaults.headers.post.Authorization = `Bearer ${token}`;

      axios.post(`${serverUrl}api/sign/user-agreement`, {}).then(
        (user) => {
          self.setState({ isLoading: false });
          console.log(user);
          setStorage("token", token);
          history.push("/");
        },
        (error) => {
          self.setState({ isLoading: false });
          console.log(error);
        }
      );
    }
  };

  render() {
    const { agreeOk, isLoading } = this.state;

    return (
      <div>
        {!isLoading && (
          <div
            style={{ width: "100%", height: "100%", verticalAlign: "center" }}
          >
            <div style={{ width: 600, margin: "0 auto", paddingTop: "30vh" }}>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: 20,
                  marginLeft: -3,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  color="default"
                  style={{ paddingLeft: 0, paddingTop: 0 }}
                  checked={agreeOk}
                  onClick={() => this.setState({ agreeOk: !agreeOk })}
                />
                <p>
                  Я ознакомился с<span> </span>
                  <span
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={this.clickAgr}
                  >
                    Соглашением об обработке персональных данных
                  </span>{" "}
                  и даю своё согласие на их обработку
                </p>
              </div>
              <div
                className="form-group"
                style={{
                  marginTop: 5,
                  justifyContent: "space-between",
                  flex: 1,
                  display: "flex",
                }}
              >
                <CustomizedButton
                  style={{ marginBottom: 5 }}
                  onClick={this.handleSubmit}
                  id="category-button-add"
                  title="Принять"
                  prim
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
