import React from "react";
import "../css/style.css";
import { connect } from "react-redux";
import { store } from "../_helpers";
import ConfItem from "./ConfItem";
import ConfPlaceholder from "./ConfPlaceholder";
import { userService, confService } from "../_services";

import { userActions, appActions } from "../_actions";
import i18next from "i18next";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

class ConfListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, start_date:null,end_date:null };
    store.subscribe(this.storeChange);
  }
  
  

  componentDidMount() {
      
     this.props.dispatch(userActions.conf_list());
     //confService.confListParticipants(false);
  }

  storeChange = (e) => {};

  onItemClick = (e) => {};

  onItemEdit = (e) => {
    this.props.dispatch(userActions.set_conf(e));
    this.props.dispatch(appActions.setpage("conf_param", e.id));
  };

  onItemSchedule = (e) => {
    this.props.dispatch(userActions.set_conf(e));
    this.props.dispatch(appActions.setpage("conf_prog", e.id));
  };

  onItemDelete = (id) => {
    const self = this;
    const res_dialog = window.confirm("Удалить конференцию?");
    if (res_dialog == true) {
      // self.props.dispatch(userActions.del_conf(id));
      // this.props.dispatch(userActions.conf_list());

    
      userService.delWrapper('api/conference/'+id,
        (data) => {
           self.props.dispatch(userActions.conf_list());
          //confService.confListParticipants(false);
        },
        (error) => {
            self.props.dispatch(userActions.set_error(error));
            
        }
      );
    }
  };
  
  changeDate = (index, event) => {
    
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  addNewConf = () => {
    this.props.dispatch(userActions.set_conf(null));
    this.props.dispatch(appActions.setpage("new_conf"));
  };

  render() {
    const self = this;
    const { isLoading, start_date, end_date } = this.state;
    const { classes, user } = this.props;
    
    console.log(start_date,user.conflist);
    
    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Конференции</h1>
          
          <div style={{width: 300, display:'flex',flexDirection:'row'}}>
            
           <div className="date-wrap form__group-row">
                           <label htmlFor="start">даты с</label>
                           <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={start_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(0, e)}
                          />
                        </div>
                        
                          <div className="date-wrap form__group-row">
                          <label htmlFor="start"> до</label>
                          <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={end_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(1, e)}
                          />
                        </div>
          </div>

          <div className="layout__btn">
            <button className="btn" onClick={self.addNewConf}>
              Создать конференцию
            </button>
          </div>
        </div>

        <ul className="conf-list">
          {user.conflist.length > 0 &&
            user.conflist
              .filter((e) => e.contracts_for_speakers == 0)
              .filter((e) => !start_date || e.start_time.substr(0,10)>start_date )
              .filter((e) => !end_date || e.end_time.substr(0,10)<end_date )
              .map((item, index) => (
                <ConfItem
                  data={item}
                  key={index}
                  onClick={() => self.onItemClick(item.id)}
                  onEdit={() => self.onItemEdit(item)}
                  onSchedule={() => self.onItemSchedule(item)}
                  onDelete={() => self.onItemDelete(item.id)}
                />
              ))}
        <ConfPlaceholder onClick={self.addNewConf}/>    
             
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedConfListPage = connect(mapStateToProps)(ConfListPage);
export { connectedConfListPage as ConfListPage };
