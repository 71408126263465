export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  REG_REQUEST: "USERS_REG_REQUEST",
  REG_SUCCESS: "USERS_REG_SUCCESS",
  REG_FAILURE: "USERS_REG_FAILURE",
  USERINFO_REQUEST: "USERS_INFO_REQUEST",
  USERINFO_SUCCESS: "USERS_INFO_SUCCESS",
  USERINFO_FAILURE: "USERS_INFO_FAILURE",
  LOGOUT: "USERS_LOGOUT",
  SET_ERROR: "SET_ERROR",
  DISABLE_ERROR: "DISABLE_ERROR",
  CONFLIST_REQUEST: "USERS_CONFLIST_REQUEST",
  CONFLIST_SUCCESS: "USERS_CONFLIST_SUCCESS",
  CONFLIST_FAILURE: "USERS_CONFLIST_FAILURE",
  CONFLIST_STUDENT_REQUEST: "USERS_CONFLIST_STUDENT_REQUEST",
  CONFLIST_STUDENT_SUCCESS: "USERS_CONFLIST_STUDENT_SUCCESS",
  CONFLIST_STUDENT_FAILURE: "USERS_CONFLIST_STUDENT_FAILURE",
  ADDCONF_REQUEST: "USERS_ADDCONF_REQUEST",
  ADDCONF_SUCCESS: "USERS_ADDCONF_SUCCESS",
  ADDCONF_FAILURE: "USERS_ADDCONF_FAILURE",
  TEAMORG_REQUEST: "USERS_TEAMORG_REQUEST",
  TEAMORG_SUCCESS: "USERS_TEAMORG_SUCCESS",
  TEAMORG_FAILURE: "USERS_TEAMORG_FAILURE",
  SET_CONF: "USERS_SET_CONF",
  SET_CONF_SELECTED: "USERS_SET_CONF_SELECTED",

  EDITCONF_REQUEST: "USERS_EDITCONF_REQUEST",
  EDITCONF_SUCCESS: "USERS_EDITCONF_SUCCESS",
  EDITCONF_FAILURE: "USERS_EDITCONF_FAILURE",

  DELCONF_REQUEST: "USERS_DELCONF_REQUEST",
  DELCONF_SUCCESS: "USERS_DELCONF_SUCCESS",
  DELCONF_FAILURE: "USERS_DELCONF_FAILURE",

  SCHEDULE_REQUEST: "USERS_SCHEDULE_REQUEST",
  SCHEDULE_SUCCESS: "USERS_SCHEDULE_SUCCESS",
  SCHEDULE_FAILURE: "USERS_SCHEDULE_FAILURE",

  SECTIONS_REQUEST: "USERS_SECTIONS_REQUEST",
  SECTIONS_SUCCESS: "USERS_SECTIONS_SUCCESS",
  SECTIONS_FAILURE: "USERS_SECTIONS_FAILURE",

  TEAM_REQUEST: "USERS_TEAM_REQUEST",
  TEAM_SUCCESS: "USERS_TEAM_SUCCESS",
  TEAM_FAILURE: "USERS_TEAM_FAILURE",

  LOADING: "ANY_REQUEST_LOADING",
};

export const serverUrl = process.env.REACT_APP_SERVER_URL;
//export const serverUrl = "https://api1.naviconf.com/";
export const helpUrl = "https://naviconf.com/help";
export const agrUrl = process.env.REACT_APP_SERVER_URL;
export const cookieText =
  "Продолжая пользоваться сайтом, вы даёте cогласие на автоматический сбор и анализ ваших данных, необходимых для работы сайта и его улучшения, а также использование файлов cookie";
