import React from "react";
import PropTypes from "prop-types";

import "../css/style.css";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import i18next from "i18next";

export class SuppPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {
    const self = this;
    const { isLoading } = this.state;
    const emailTo = "support@naviconf.com";

    return (
      <div className="layout">
        <h1 className="layout__title">Поддержка</h1>
        <div>Поддержка пользователей</div>
        <a
          style={{ textColor: "#00f", marginTop: 10 }}
          href={"mailto:" + emailTo}
        >
          {emailTo}
        </a>
      </div>
    );
  }
}
