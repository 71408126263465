import React from "react";

import { history } from "../_helpers";

export default class VerifyPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
      
   //history.push("/");
   
  }

  render() {

    return (
    
    <div style={{ width: "100%", height: "100%", verticalAlign: "center" }}>
        <div style={{ width: 400, margin: "0 auto", paddingTop: "30vh" ,fontSize: 20}}>
    
    <div>
    <div style={{paddingBottom: 20}}>Ваша регистрация на платформе NaviConf успешно завершена. Спасибо!</div>
    <button onClick={()=>history.push("/")}>
    <span style={{ color: "blue", textDecoration: "underline", fontSize:20 }}>
              Перейти в личный кабинет
            </span>
    </button>
    </div>
    </div>
    </div>
    )
    
    
  }
}

