import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from 'react-redux'
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { userService } from "../_services";
import { userActions, appActions } from "../_actions";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import "../css/style.css";

const F_TEXT= {'firstname':'Имя','lastname':'Фамилия','patronymic':'Отчество','position':'Должность'}
//'department':'Компания'


const firmsList = [
{label:'ПАО «Интер РАО»'},
{label:'АО «Алтайэнергосбыт»'},
{label:'АО «ЕИРЦ ЛО»'},
{label:'АО «ЕИРЦ СПб»'},
{label:'АО «Интер РАО – Электрогенерация»'},
{label:'АО «Мосэнергосбыт»'},
{label:'АО «ОмскРТС»'},
{label:'АО «ПСК»'},
{label:'АО «ПЭС»'},
{label:'АО «ТГК-11»'},
{label:'АО «Томская генерация»'},
{label:'АО «ТомскРТС»'},
{label:'АО «Томскэнергосбыт»'},
{label:'ООО «БашРТС»'},
{label:'ООО «БГК»'},
{label:'ООО «БизКомм»'},
{label:'ООО «Интер РАО – Инжиниринг»'},
{label:'ООО «Интер РАО – ИТ»'},
{label:'ООО «Интер РАО – Управление сервисами»'},
{label:'ООО «Интер РАО – Центр управления закупками»'},
{label:'ООО «Интер РАО – Экспорт»'},
{label:'ООО «МосОблЕИРЦ»'},
{label:'ООО «Орловский энергосбыт»'},
{label:'ООО «ОРЦ»'},
{label:'ООО «ОЭК»'},
{label:'ООО «Сигма»'},
{label:'ООО «ССК»'},
{label:'ООО «ЭСВ»'},
{label:'ООО «ЭСКБ»'},
{label:'ПАО «Саратовэнерго»'},
{label:'ПАО «Тамбовская энергосбытовая компания»'},
{label:'Фонд «Энергия без границ»'}];


export default function UserInfoDialog(props) {
  
  
  const [data,setData] = React.useState({});
  const [count, setCount] = React.useState(0);
  const dispatch = useDispatch();
  const { handleClose, flds, dialogOpen } = props;
  const [firm,setFirm] = React.useState(null);
  
  function openPdf()
  {
      
      window.open("https://naviconf.com/agreements/Personal_data_agreement.pdf","_blank");
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
    setData(null);
  }

  function saveData() {
      if (data && flds)
      {
          
          let isOk = true;
          
          for (let i=0;i<flds.length;i++)
              if (!data[flds[i]] || data[flds[i]].length<2)
                  isOk = false;
              
         if (firm)
            {
                data['department']=firm.label
            }             
              
            if (isOk && firm)
            {
                console.log(data);
                handleClose({...data});
                setCount(0);
                setData(null);
                setFirm(null);
            }
            else
                dispatch(userActions.set_error("Слишком короткие значения полей"));
            
      }
  }
  
  function setInfo(name,val) {
      
      let tmpData = {...data}
      tmpData[name] = val;
      setData(tmpData);
  }
  
  console.log(firm);

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Заполните анкету</DialogTitle>
      <DialogContent style={{ minWidth:280,maxWidth: 580 }}>
       {flds &&  flds.filter(e=>e != 'department').map(function(item,index)
           {
               
            return (<TextField
            autoFocus
            margin="dense"
            id={item}
            label={F_TEXT[item]}
            fullWidth
            variant="standard"
            value={(data && data[item])?data[item]:''}
            required
            onChange={(e) => setInfo(item,e.target.value)}
          />)
               
       })}
        <Autocomplete
      style={{marginTop:20}}
      id="combo-box-demo"
      options={firmsList}
      value={firm}
      renderInput={(params) => <TextField {...params} label="Компания" />}
       onChange={(event,val) => setFirm(val)}
    />
    <div style={{marginTop:20, fontSize:16}}>
    Нажимая "зарегистрироваться" я даю <span style={{color:'#0f2d51',textDecoration:'underline',cursor:'pointer'}}
    onClick={openPdf}>согласие на обработку персональных данных</span>
    </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>Зарегистрироваться</Button>
        <Button onClick={cancelData}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
