import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";

export default function EditDialog(props) {
  const {
    handleClose,
    label,
    title,
    oldVal,
    dialogOpen,
    dialogType,
    fileStatus,
    onFileUpload,
    isSlotService,
  } = props;
  
  console.log(props);

  const [count, setCount] = React.useState(0);
  const [value, setValue] = React.useState(oldVal ? oldVal.name : "");
  const [editNameDisabled,setEditNameDisabled] = React.useState(false);

  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [isServiceSlot, setServiceSlot ] = React.useState(false);

  React.useEffect(() => {
    if (count == 0) {
      setValue(oldVal ? oldVal.name : "");

      if (dialogType == "speaker") {
        console.log(oldVal);
        setDesc(oldVal.description ? oldVal.description : "");
        setCity(oldVal.city ? oldVal.city : "");
        setPhone(oldVal.phone ? oldVal.phone : "");
        setComm(oldVal.comment ? oldVal.comment : "");
        setEmail(oldVal.email ? oldVal.email : "");
      } else if (dialogType == "theme")
      {
        console.log(oldVal);
        
        if (oldVal){
            
        if (oldVal.slot_type == "lesson")        
        {
            //setDesc(oldVal.entity.description ? oldVal.entity.description : "");
            setDesc(oldVal.description ? oldVal.description : "");
            setValue(oldVal.entity ? oldVal.entity.name : "");
        }
            else
        setDesc(oldVal.description ? oldVal.description : "");
    
        if (oldVal.slot_type =="service_lesson")
        {
            setValue(oldVal.entity ? oldVal.entity.name : "");
            setEditNameDisabled(true);
        }
        
        setServiceSlot(oldVal.isSlotService? true:false);
        }
      }
    }
  });

  function editVal(e) {
    setValue(e.target.value);
    setCount(count + 1);
  }

  function saveTheme() {
    if (value != "") {
      const tmpObj = { name: value };

      if (dialogType == "speaker") {
        tmpObj.description = description;
        tmpObj.phone = phone;
        tmpObj.city = city;
        tmpObj.comment = comm;
        tmpObj.email = email;
      } else if (dialogType == "theme" && !isSlotService) tmpObj.description = description;

      handleClose(tmpObj);
      setCount(0);
      setValue("");
      setValue("");
      setPhone("");
      setDesc("");
      setEmail("");
      setComm("");
      setCity("");
      setEditNameDisabled(false);
    }
  }

  function cancelTheme() {
    handleClose(null);
    setCount(0);
    setValue("");
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setEditNameDisabled(false);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]);
    }
  }
  
  console.log(oldVal);

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={label}
          fullWidth
          variant="standard"
          value={value}
            disabled={editNameDisabled}
          onChange={(e) => editVal(e)}
        />
        {(dialogType == "speaker" || (dialogType == "theme" && !isSlotService) ) && (
          <TextField
            autoFocus
            margin="dense"
            id="desc"
            label="Описание"
            fullWidth
            variant="standard"
            multiline
            value={description}
            onChange={(e) => {
              setDesc(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label="Телефон"
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label="Город"
            fullWidth
            variant="standard"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="comm"
            label="Комментарий"
            fullWidth
            variant="standard"
            multiline
            value={comm}
            onChange={(e) => {
              setComm(e.target.value);
              setCount(count + 1);
            }}
          />
        )}

        {dialogType == "theme" && oldVal.slot_type !=="service_lesson" && !isSlotService && (
          <div style={{ marginTop: 10 }}>
            <div style={{ paddingBottom: 5 }}>Файл презентации</div>
            <Dropzone onDrop={onDrop} class="visually--hidden">
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    width: 400,
                    height: 50,
                    background: "#36B2F1",
                    borderRadius: 5,
                  }}
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                >
                  <input {...getInputProps()} />

                  {fileStatus == 0 &&
                    (isDragActive ? (
                      <p style={{ fontSize: 12 }}>Загрузить файл</p>
                    ) : (
                      <p style={{ fontSize: 12, padding: 10, borderRadius: 5 }}>
                        Перетащите файл сюда или нажмите для загрузки файла
                      </p>
                    ))}
                  {fileStatus == 1 && (
                    <p style={{ fontSize: 12, padding: 10 }}>Файл загружен</p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveTheme}>Сохранить</Button>
        <Button onClick={cancelTheme}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
