import React from "react";
import PropTypes from "prop-types";
import "../css/style.css";

import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import { userActions, appActions } from "../_actions";
import PartItem from "./PartItem";
import { userService, confService } from "../_services";
import { store, history } from "../_helpers";
import { storeData, setStore } from "../store.js";
import { SendButton, TextFieldCell, Snack } from "../_components";

class PartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, team: [], nameF:'', confF:'',ticketF:'',dateF:'',sortNameDesc:false};
  }

  componentDidMount() {
    this.getTeam();
  }

  getTickets = (id) => {
    // userService.getAsync(['api/ticket-type?conference_id='+id,'api/ticket-type?conference_id='+id],function(data){console.log(data)});
  };

  getConfs = () => {};

  getTeam = () => {
    const self = this;

    console.log(this.props.user);

    confService.confListStudents(
      (data) => {
        
		
        self.setState({ team: [...data], isLoading: false });
      },
      (err) => {
        console.log(err);
      }
    );
  };
  
    editFilter = (name, val) => {
    
    if (name == "name") this.setState({ nameF: val });
    else if (name == "conf") this.setState({ confF: val });
    else if (name == "date") this.setState({ dateF: val });
    else if (name == "ticket") this.setState({ ticketF: val });
  };


  render() {
    const self = this;
    const { isLoading, team, roles_list, nameF, confF,dateF,ticketF, sortNameDesc } = this.state;
    const { classes } = this.props;

    console.log(this.state);
    
    // {this.props.conf_name}

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Участники конференций</h1>
        </div>
		
        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--city">
			  <div style={{display:'flex',flexDirection:'row'}}>
			  <div onClick={()=>this.setState({sortNameDesc:!sortNameDesc})}>
			  {sortNameDesc ? <ExpandLess  fontSize="large"
								/> : 
									<ExpandMore  fontSize="large"
								/>}
				</div>
                <input type="text" placeholder="Поиск по имени" 
                value={nameF}
                  onChange={(e) => self.editFilter("name", e.target.value)}
                />
				</div>
              </div>
			  <div className="tbl__item tbl__item--city">
                <input type="text" placeholder="Поиск по конференции" 
                  value={confF}
                  onChange={(e) => self.editFilter("conf", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder="Поиск по билету"
                value={ticketF}
                onChange={(e) => self.editFilter("ticket", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder="Поиск по дате" 
                value={dateF}
                onChange={(e) => self.editFilter("date", e.target.value)}
                />
              </div>
              
            </div>
          </div>

          <div className="tbl__body">
            {team.length>0 && 
            team.filter(function(e){
				
				
                if (!e) return false;
                
                return(
				(e.user_name.toLowerCase().indexOf(nameF.toLowerCase())>=0) &&
              (e.conf_name.toLowerCase().indexOf(confF.toLowerCase())>=0) 
            && e.updated_at.toLowerCase().indexOf(dateF.toLowerCase())>=0 && e.ticket_name.toLowerCase().indexOf(ticketF.toLowerCase())>=0)} ).
			sort(function(a,b) {
				
				var nameA=a.user_name.toLowerCase(), nameB=b.user_name.toLowerCase();
				
				
				if (!sortNameDesc)
				{
				if (nameA < nameB)
				  return -1
				if (nameA > nameB)
				  return 1
				return 0
				}
				else
				{
					if (nameA < nameB) 
				  return 1
				if (nameA > nameB)
				  return -1
				return 0
					
				}
				
				
			}).
            map((item, index) => <PartItem data={item} />)}
          </div>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedPartPage = connect(mapStateToProps)(PartPage);
export { connectedPartPage as PartPage };
