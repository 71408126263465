import React from "react";
import PropTypes from "prop-types";

import "../css/style.css";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import i18next from "i18next";

export class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {
    const self = this;
    const { isLoading } = this.state;

    return (
      <div className="layout">
        <h1 className="layout__title">Настройки</h1>
        <div>Используется корпоративная лицензия</div>
      </div>
    );
  }
}
