import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function MoveDialog(props) {
  const [sect, setSect] = React.useState("");
  const [count, setCount] = React.useState(0);

  const { handleClose, dialogOpen, sect_list, sect_id } = props;

  React.useEffect(() => {
    if (count == 0) {
      setSect("");
    }
  });

  function selectSect(e) {
    setSect(e);
    setCount(1);
  }

  function saveData() {
    handleClose(sect);
    setCount(0);
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Выберите секцию для переноса</DialogTitle>
      <DialogContent>
        <select onChange={(e) => selectSect(e.target.value)}>
          <option value={0} selected>
            Выберите секцию
          </option>
          {sect_list
            .filter((e) => e.id != sect_id)
            .map((item, index) => (
              <option value={item.id}>{item.name}</option>
            ))}
        </select>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>Сохранить</Button>
        <Button onClick={cancelData}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
