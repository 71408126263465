import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";


import Select, { SelectChangeEvent } from "@mui/material/Select";
import { userService } from "../_services";

import "../css/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 250,
  maxWidth: 320,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};



export default function QRDialog(props) {
	
  const [count, setCount] = React.useState(0);

  const { handleClose, qrData, dialogOpen } = props;

  React.useEffect(() => {});

  function cancelData() {
    handleClose(-1);
    setCount(0);
  }

  function selectTicket(id) {
    handleClose(id);
    setCount(0);
  }

  return (
   
	  <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={dialogOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={dialogOpen}>
        <Box sx={style}>
		<div style={{fontSize:20}}>QR-код билета</div>
           {qrData && <div dangerouslySetInnerHTML={{__html:  qrData}} />
		  }
        </Box>
      </Fade>
    </Modal>
  );
}
