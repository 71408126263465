import React from "react";
import "../css/style.css";
import { serverUrl } from "../_constants";
import confLogo from "../img/conf_logo.png";
import { confService } from "../_services";

export default function ConfPlaceholder(props) {

  const { data } = props;

  return (
    <li className="conf-list__item">
      <div className="card" style={{minHeight:300}}>
         <button className="block-dropdwon__btn" style={{width: '100%',height:'100%',fontSize:20}} onClick={props.onClick}
    >Добавить конференцию</button>
      </div>
    </li>
  );
}
