import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function BldDialog(props) {
  const [address, setAddress] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [person, setPerson] = React.useState("");
  const [comment, setComment] = React.useState("");

  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    dAddress,
    dName,
    dEmail,
    dPerson,
    dPhone,
    dComment,
    id,
    dialogOpen,
  } = props;

  React.useEffect(() => {
    if (count == 0) {
      setAddress(dAddress);
      setName(dName);
      setEmail(dEmail);
      setPhone(dPhone);
      setPerson(dPerson);
      setComment(dComment);
    }
  });

  function saveData(e) {
    e.preventDefault();

    if (id == 0) {
      if (address != "") {
        handleClose({ address, name, email, phone, person, comment });
        setCount(0);
      }
    } else if (address != "" && id > 0) {
      handleClose({ address, name, email, phone, person, comment });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>Площадка</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent style={{ width: 500 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Название"
            fullWidth
            variant="standard"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Адрес"
            fullWidth
            variant="standard"
            value={address}
            required
            multiline
            onChange={(e) => {
              setAddress(e.target.value);
              setCount(count + 1);
            }}
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="E-mail"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="person"
            label="Контактное лицо"
            fullWidth
            variant="standard"
            value={person}
            onChange={(e) => {
              setPerson(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Телефон"
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Комментарий"
            fullWidth
            variant="standard"
            value={comment}
            multiline
            onChange={(e) => {
              setComment(e.target.value);
              setCount(count + 1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Сохранить</Button>
          <Button onClick={cancelData}>Отмена</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
