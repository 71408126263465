import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "../css/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 320,
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const no_text = "уточняется";

function getPhone(s0)
{
    
    if (!s0)
        return;
    let s = s0;
    let phones = [];
    
    var re = /(?:\+|\d)[\d\-\(\) ]{9,}\d/g;
    
    let array = [...s.matchAll(re)];
    
    for (let i=0;i<array.length;i++)
    {
        let tel_tmp = array[i][0];
        let new_tel = tel_tmp;
        
        while (new_tel.indexOf(' ')>=0 || new_tel.indexOf('-')>=0 || new_tel.indexOf('(')>=0 || new_tel.indexOf(')')>=0)
        {
            new_tel = new_tel.replace(' ','');
            new_tel =  new_tel.replace('-','');
			new_tel =  new_tel.replace('(','');
			new_tel =  new_tel.replace(')','');
        }
        
         s0 = s0.replace(tel_tmp,'<span style="color: blue" ><a href="tel:'+new_tel+'">'+tel_tmp+'</a> <a href="https://t.me/'+new_tel+'" target="_blank" >(Отправить сообщение)</a></span>');
    }
    
    
    re = /([\w.-]+@([\w-]+)\.+\w{2,})/g;
    
    array = [...s.matchAll(re)];
    
    
    for (let i=0;i<array.length;i++)
    {
        let email_tmp = array[i][0];
         s0 = s0.replace(email_tmp,'<span style="color: blue" ><a href="mailto:'+email_tmp+'">'+email_tmp+'</a></span>');
    }
    
    s0 = '<div>'+s0+'</div>';
    
      return s0;

        
}

export default function ContactInfo(props) {
  const [count, setCount] = React.useState(0);
  const { handleClose, data, open } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2" style={{whiteSpace: 'pre-wrap' }}>
            {data && 
             <div 
                          dangerouslySetInnerHTML={{__html:  getPhone(data)}}/>
           }
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}
