import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../css/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { serverUrl } from "../_constants";

/*
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.cost : ""}</span>
        </div>
		*/

export default function PartItem(props) {
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div className="tbl__item tbl__item--city" title="Нажмите чтобы увидеть QR-код билета" onClick={()=>props.onItemClick(props.data.id)}>
          <span style={{textDecoration:'underline'}}>{props.data ? props.data.conf_name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.dates : ""}</span>
        </div>
		
      </div>
    </div>
  );
}
