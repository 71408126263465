import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import i18next from "i18next";
import { initLanguage } from "../_helpers";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import { userService } from "../_services";
import { TextField } from "@mui/material";
import { responseErrorToText } from "../_helpers";
import { Snack } from "../_components";
import { NoAuthPage } from "./NoAuthPage";
import { history } from "../_helpers";

//const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;
const loginIsEmail = false;

export default class PasswordForgotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
    initLanguage();
  }

  sendResetEmail = (e) => {
    e.preventDefault();
    store.dispatch(userActions.loading(true));
    userService.postWrapper(
      "api/password/forgot",
      { email: this.state.email },
      (data) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText:
            i18next.t("Email with reset password link has been sent to ") +
            this.state.email,
          alertShow: true,
          errType: "success",
        });
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: responseErrorToText(error),
          alertShow: true,
          errType: "error",
        });
      }
    );
  };

  render() {
    const form = (
      <form onSubmit={this.sendResetEmail}>
        <div>
          <TextField
            label="email"
            onChange={(e) => this.setState({ email: e.target.value })}
            variant="standard"
          />
        </div>
        <CustomizedButton
          type="submit"
          title={i18next.t("Reset password")}
          prim
          className="form-button"
        />
      </form>
    );
    const snack = (
      <Snack
        open={this.state.alertShow}
        variant={this.state.errType}
        text={this.state.snackText}
        onClose={() => this.setState({ alertShow: false })}
      />
    );
    return (loginIsEmail
?<NoAuthPage form={form} snack={snack} />:<div style={{ height: '100vh', display: 'flex',
    flexDirection: 'column',
   alignItems: 'center' ,
   justifyContent: 'center'}}> 
    <div style={{marginLeft: 30,marginRight:30, marginBottom: 20, fontSize:30}}>Если вы не помните ваш пароль и ваш логин не связан с адресом e-mail - для восстановления напишите на почту <a style={{fontWeight:800}} href="mailto:support@naviconf.com">support@naviconf.com</a> ваши логин, фамилию, имя и отчество для подтверждения доступа и получения ссылки для смены пароля</div>
    <CustomizedButton
          title={"Вернуться"}
          prim
          className="form-button"
          onClick={()=>history.push('/login')}
        /></div>);
  }
}
