import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import "../css/style.css";

const no_text = "нет данныx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MemberInfo(props) {
  const { handleClose, data, open } = props;

  React.useEffect(() => {});

  console.log(data);

  function openUrl() {
    if (data.url_online) {
      if (data.url_online.indexOf("https:") != 0)
        window.open("https://" + data.url_online);
      else window.open(data.url_online);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {data && (
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Имя: {data.name ? data.name : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              E-mail: {data.email ? data.email : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Телефон: {data.phone ? data.phone : no_text}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Город: {data.city ? data.city : no_text}
            </Typography>
          </Box>
        )}
      </Fade>
    </Modal>
  );
}
