import React from "react";
import PropTypes from "prop-types";
import { history, store } from "../_helpers";
import { userService, confService } from "../_services";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { SendButton, TextFieldCell, Snack } from "../_components";
import { DeleteButton } from "../_components/buttons/DeleteButton";
import { EditButton } from "../_components/buttons/EditButton";
import "../css/style.css";
import { CircularProgress } from "@material-ui/core";
import { DeleteDialog } from "../_components";
import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";
import { userActions, appActions } from "../_actions";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import i18next from "i18next";
import confLogo from "../img/conf_logo.png";

import SponsorDialog from "./SponsorDialog";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CopyContentIcon from "@material-ui/icons/FileCopy";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import CompanyForm from "./CompanyForm";
import BldDialog from "./BldDialog";
import { serverUrl } from "../_constants";

const TEXT_NAME_NOT_EMPTY = "Имя файла не должно быть пустым";
const TEXT_LOAD_FILE = "Загрузите файл";
const TITLE_LOAD_FILE = "Загрузить файл";
const TEXT_DROP_FOR_LOAD =
  "Перетащите файл сюда или нажмите для загрузки файла";
const TEXT_FILE_LOADED = "Файл загружен";
const TEXT_SELECT_DIR = "Выберите каталог!";
const TEXT_FOR_BUY =
  "Для включения возможности продажи билетов свяжитесь с sales@naviconf.com";

const err_text = "Не удалось выполнить запрос";
const start_end_date_text = "Дата окончания не должна быть раньше даты начала";
const start_date_in_past_text = "Нельзя создать конференцию на прошедшие даты!";
const SAVE_CONF_TEXT = 'Сохранить конференцию';

class CreateConfPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",
      isOpen: false,
      loadingTickets: false,
      streamUrl: "",
      sponsors: [],
      sp_list: [],
      tickets: [],
      tickets_tmp: [],
      name: "",
      id: 0,
      theme: "ИТ, Технологии, экономика",
      date: "3",
      adress: "4",
      part_count: "0",
      spons_count: "0",
      start_date: null,
      end_date: null,
      blds: [],
      selectedBldId: 0,
      bldAddr: "",
      bldDialog: false,
      bldId: 0,
      spId: 0,
      spDialog: false,
      dName: "",
      dAddress: "",
      dEmail: "",
      format: "hybrid",
      contacts: "",
      wifi: "",
      faq: "",
      comment: "",
      urlt: "",
      url_old: "",
      url_id: "",
      is_published: 0,
      brief: "",
      brief_id:0,
    };
    // store.subscribe(this.storeChange)
  }

  storeChange = (e) => {
    const { classes, dispatch } = this.props;

    if (store.getState().user.isLoading == false) {
      if (store.getState().user.error == "") {
        dispatch(appActions.setpage("conf_list"));
      }
    }
  };

  componentDidMount() {
    

    const { selectedConf } = this.props.user;

    if (!(selectedConf && selectedConf.id)) {
      this.props.dispatch(appActions.setpage("new_conf"));
    }

    if (selectedConf && selectedConf.id) {
        
        
      
      //this.getSponsors(selectedConf.id);
      
      this.getTickets(selectedConf.id);
      this.getBuildings(selectedConf.building_id);
      this.getConfInfo(selectedConf.id);
      this.getUrlT(selectedConf.id);
    } else this.getBuildings();
    this.getSponsors(0);
  }
  
  setConfToState(confData)
  {
      this.setState({
        id:confData.id,
        name: confData.name,
        format: confData.type,
        comment: confData.description,
        streamUrl: confData.navigation_description,
        id: confData.id,
        faq: confData.faq,
        wifi: confData.wifi,
        contacts: confData.contacts,
        is_published: confData.is_published,
        image: confData.image,
        start_date: confData.start_time
          ? confData.start_time.substr(0,10)
          : null,
        end_date: confData.end_time
          ? confData.end_time.substr(0,10)
          : null,
        start_time: confData.start_time
          ? confData.start_time.substr(11,5)
          : null,
        end_time: confData.end_time
          ? confData.end_time.substr(11,5)
          : null,
        selectedBldId: confData.building_id,
        part_count: confData.participants_count,
        sp_list: confData.sponsors,
        spons_count: confData.sponsors_count,
        

      });
      
      let brief_docs = confData.documents.filter(e=>e.type =="presentation_template");
      
      if (brief_docs.length>0)
      {
          this.setState({brief_id:brief_docs[0].id,brief:brief_docs[0].description});
          
      }
      
      
  }
  
  getBrief = () => {
   
    
  }
  
  saveBrief = (conf_id = undefined) => {
    
    const { classes, dispatch } = this.props;
    
    let { id, brief, brief_id } = this.state;
    
    if (conf_id)
        id = +conf_id;
     
    if (id>=0 && brief)
    {
        
        let post_data = {
             documentable_id: id,
             documentable_type: 'conference',
             type: 'presentation_template',
             description: brief,
             access: 'team',
             link:'https://naviconf.com/',
        }
        
        if (brief_id>0)
        {
            post_data.id = brief_id;
        }
        
          userService.postWrapper(
              "api/document",
              post_data,
              (data) => {
                
              },
              (error) => {
                if (error != "") {
                  dispatch(userActions.set_error(err_text));
                }
              }
            );
    }    
    
    if (id>=0 && brief == '' && brief_id)
    {
        
          userService.delWrapper(
              "api/document/"+brief_id,
              (data) => {
                 console.log(data);   
              },
              (error) => {
                if (error != "") {
                  dispatch(userActions.set_error(err_text));
                }
              }
            );
    }
  }

  getConfInfo = (id, is_save = false) => {
    const self = this;
    const { classes, dispatch } = this.props;

    confService.confInfo(
      id,
      (data) => {
        if (data.status == 200) {
            
            
          self.setConfToState(data.data);

          if (is_save) dispatch(userActions.set_conf({ ...data.data }));
        }
      },
      (error) => {
        dispatch(userActions.set_error(err_text));
      }
    );
  };

  editSponsors = () => {
    const { id } = this.state;

    this.setState({ spId: id, spDialog: true });
  };

  resetConf = () => {
    this.setState({
      name: "",
      start_time: null,
      end_time: null,
      selectedBldId: null,
      wifi: "",
      comment: "",
      faq: "",
      contacts: "",
    });
  };

  showTextForBuy = () => {
    const { classes, dispatch } = this.props;

    dispatch(userActions.set_error(TEXT_FOR_BUY, "info"));
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    

    if (acceptedFiles.length > 0) {
      // this.setState({ selectedFile: acceptedFiles[0],loadedProgress: 0, uploadState:0});

      this.uploadFile(acceptedFiles[0]);
    }
  };

  uploadFile(selectedFile) {
    const self = this;
    const { classes, dispatch } = this.props;
    const data_upload = new FormData();
    data_upload.append("image", selectedFile);
    userService.uploadFile(
      `api/conference/${this.state.id}/upload`,
      data_upload,
      (data) => {
        
        if (data && data.data && data.data.image)
          self.setState({ image: data.data.image });
        else dispatch(userActions.set_error(err_text));
      },
      (error) => {
        

        dispatch(userActions.set_error(err_text));
      }
    );
  }

  saveTicket = (index) => {
    const { id, tickets_tmp } = this.state;
    const { classes, dispatch } = this.props;

    const tmp_t = { ...tickets_tmp[index] };

    if (tmp_t.name != "") {
      const ticketId = tmp_t.id;

      // let post_data = {conference_id: id,...tmp_t};
      const post_data = { conference_id: id, name: tmp_t.name };

      if (tmp_t.cost > 0) post_data.cost = tmp_t.cost;
      else post_data.cost = null;

      let self = this;
      this.setState({ loadingTickets: true });

      if (ticketId > 0) {
        userService.patchWrapperBody(
          `api/ticket-type/${ticketId}`,
          post_data,
          (data) => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              self.setState({ error, loadingTickets: false });
              dispatch(userActions.set_error(err_text));
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/ticket-type",
          post_data,
          (data) => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              dispatch(userActions.set_error(err_text));
            }
          }
        );
      }
    }
  };

  delTicket = (index) => {
    const { id, tickets_tmp } = this.state;
    const { classes, dispatch } = this.props;
    const tmp_t = { ...tickets_tmp[index] };
    const ticketId = tmp_t.id;
    let self = this;

    if (ticketId > 0) {
      this.setState({ loadingTickets: true });

      userService.delWrapper(
        `api/ticket-type/${ticketId}`,
        (data) => {
          self.setState({ loadingTickets: false });
          self.getTickets(self.state.id);
        },
        (error) => {
          if (error != "") {
              self.setState({ loadingTickets: false });
            dispatch(userActions.set_error(error));
            
          }
        }
      );
    } else {
      tickets_tmp.splice(index, 1);
      this.setState({ tickets_tmp: [...tickets_tmp] });
    }
  };

  editTicket = (indexT, val, type) => {
    if (val != null) {
      const { tickets_tmp } = this.state;

      if (indexT >= 0) {
        const tmp_obj = { ...tickets_tmp[indexT] };
        if (type == "name") tmp_obj.name = val;
        if (type == "cost") tmp_obj.cost = val;

        tickets_tmp[indexT] = { ...tmp_obj };
        this.setState({ tickets_tmp });
      }
    }
  };

  addTicket = () => {
    const { tickets_tmp } = this.state;

    tickets_tmp.push({ id: 0, name: "", cost: "" });
    this.setState({ tickets_tmp });
  };

  getTickets = (id) => {
    let self = this;
    const { classes, dispatch } = this.props;

    if (id > 0) {
      this.setState({ loadingTickets: true });

      userService.getWrapper(
        `api/ticket-type?conference_id=${id}`,
        (data) => {
            
          if (data.data) {
            let tmp_data = [...data.data];
            tmp_data = tmp_data.map((item, index) => ({
              ...item,
              cost: item.cost ? item.cost : 0,
            }));
            self.setState({ tickets_tmp: tmp_data, loadingTickets: false });
          } else self.setState({ tickets_tmp: [], loadingTickets: false });
        },
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(error));
          }
        }
      );
    }
  };

 
  getSponsors = (id) => {
    let self = this;
    const { classes, dispatch } = this.props;

    let getUrl = "api/sponsor";
    if (id > 0) getUrl += `?conference_id=${id}`;

    userService.getWrapper(
      getUrl,
      (data) => {
        if (id > 0) {
          if (data.data)
            self.setState({
              sponsors: [...data.data],
              spons_count: `${data.data.length}`,
            });
          else self.setState({ sponsors: [] });
        } else if (data.data) self.setState({ sp_list: [...data.data] });
        else self.setState({ sp_list: [] });
      },
      (error) => {
        if (error != "") {
          self.setState({ error });
          dispatch(userActions.set_error(error, "error"));
        }
      }
    );
  };

  setBld = (id) => {
    let addr = "";
    const { blds } = this.state;

    for (let i = 0; i < blds.length; i++)
      if (blds[i].id == id) addr = blds[i].address;

    this.setState({ selectedBldId: id, bldAddr: addr });
  };

  getBuildings = (id = 0) => {
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        
        if (data.data) {
          if (id > 0) {
            let addr = "";
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].id == id) {
                addr = data.data[i].address;
                break;
              }
            }
            self.setState({
              blds: [...data.data],
              bldAddr: addr,
              selectedBldId: id,
            });
          } else self.setState({ blds: [...data.data] });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };
  
  setConfData = () =>
  {
      const { classes, dispatch } = this.props;
      
      const {
      name,
      theme,
      date,
      adress,
      part_count,
      spons_count,
      comment,
      start_date,
      end_date,
      start_time,
      end_time,
      selectedBldId,
      faq,
      wifi,
      contacts,
      format,
      tickets_tmp,
      id,
    } = this.state;

    const self = this;
    
    let selectedBldOk = true;

    if (format != "online" && !selectedBldId) selectedBldOk = false;

    if (!(name && start_date && end_date && selectedBldOk))
    {
    
        let tmp_str = "Заполните поля - название, даты";
      

      if (format != "online" && !selectedBldId)
        tmp_str += ", выберите площадку";

      dispatch(userActions.set_error(tmp_str, "warning"));
      
      return;
    }
    
      let tmp_start_time = '09:00';
        let tmp_end_time = '18:00';
        
        if (start_time)
            tmp_start_time = start_time;
        
        if (end_time)
            tmp_end_time = end_time;

      const endDate = new Date(end_date+"T"+tmp_end_time+":00.000Z");
      let curDate = new Date();
      curDate.setDate(curDate.getDate() - 1);
      const startDate = new Date(start_date+"T"+tmp_start_time+":00.000Z");
      

      if (endDate < startDate) {
        dispatch(userActions.set_error(start_end_date_text, "warning"));
        
        return;
        
      } 
      
      if (id==0 && curDate > startDate || id>0 && curDate>endDate ) {
        dispatch(userActions.set_error(start_date_in_past_text, "warning"));
        return;
      } 
        const post_obj = {
          name: name,
          start_time: start_date + "  "+tmp_start_time+":00",
          end_time: end_date + "  "+tmp_end_time+":00",
          description: comment,
          faq: faq,
          wifi: wifi,
          contacts: contacts,
          type: format,
        };
        
        if (selectedBldId) post_obj.building_id = selectedBldId;
        
        return {...post_obj}
  }

  saveConf = (is_publish = 0) => {
      
      let self = this;
    const { classes, dispatch } = this.props;
    const { tickets_tmp, start_date, start_time } = this.state;
    
    
        let post_obj = this.setConfData();
        
        if (!post_obj)
        {
            return;
        }
        
        if (is_publish == 1 && tickets_tmp.length == 0) {
                  dispatch(
                    userActions.set_error(
                      "Перед публикацией необходимо указать типы билетов",
                      "warning")
                  );
                  return;
                }
    
        
        if (is_publish == 1 && tickets_tmp.length > 0)
        {
            let isFail = false;
            for (let i=0;i<tickets_tmp.length;i++)
                if (tickets_tmp[i].name == '')
                    isFail = true;
                
            if (isFail)
            {
                 dispatch(
                    userActions.set_error(
                      "Названия типов билетов не могут быть пустыми",
                      "warning")
                  );
                  return;
            }    
        }  
        

        if (is_publish >= 0) post_obj.is_published = is_publish;
        //else post_obj.is_published = 0;
        
        userService.patchWrapperBody(
          `api/conference/${this.state.id}`,
          post_obj,
          (data) => {
            const tmp_str = "Изменения сохранены";
            dispatch(userActions.set_error(tmp_str, "success"));

            if (is_publish >= 0) self.setState({ is_published: is_publish });

            self.getConfInfo(self.state.id, true);
            self.setUrlT(self.state.id);
          },
          (error) => {
            
            dispatch(userActions.set_error(error, "error"));
          }
        );
        
        this.saveBrief();
     
  };

  setUrlT = (id) => {
    let { urlt, url_id, url_old, format } = this.state;
    const { classes, dispatch } = this.props;

    let self = this;

    if (format == "offline") return;

    let postObj = {
      redirect: urlt,
      entity_type: "conference",
      entity_id: id,
    };

    if (urlt)
      if (
        (urlt.indexOf("http://") < 0 && urlt.indexOf("https://") < 0) ||
        confService.validateUrl(urlt) == false
      ) {
        dispatch(
          userActions.set_error("Неправильный формат ссылки", "warning")
        );
        return;
      }

    if (urlt != url_old) {
      if (!url_id) {
        if (urlt)
          userService.postWrapper(
            "api/link",
            postObj,
            (data) => {
              
              self.setState({
                url_old: data.data.redirect,
                url_id: data.data.id,
              });
            },
            (error) => {
              dispatch(userActions.set_error(error));
            }
          );
      } else if (urlt) {
        userService.patchWrapper(
          "api/link/" + url_id + "?redirect=" + urlt,
          (data) => {
            
            self.setState({
              url_old: data.data.redirect,
              url_id: data.data.id,
            });
          },
          (error) => {
            dispatch(userActions.set_error(error));
          }
        );
      } else {
        userService.delWrapper(
          "api/link/" + url_id,
          (data) => {
            
            self.setState({ url_id: "", url_old: "" });
          },
          (error) => {
            dispatch(userActions.set_error(error));
          }
        );
      }
    }
  };

  getUrlT = (id) => {
    let { urlt } = this.state;
    const { classes, dispatch } = this.props;

    let self = this;

    userService.getWrapper(
      "api/link?conference_id=" + id,
      (data) => {
       
        if (data.data.length > 0) {
          let { id, redirect } = data.data[0];
          self.setState({ url_id: id, urlt: redirect, url_old: redirect });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  addConf = (is_publish = 0) => {
    const self = this;
    const { classes, dispatch } = this.props;
    const { selectedBldId } = this.state;
    
    let post_obj = this.setConfData();
    
    if (!post_obj)
        return;

        if (selectedBldId) post_obj.building_id = selectedBldId;

        userService.postWrapper(
          "api/conference",
          post_obj,
          (data) => {
            
            if (data.data && data.data > 0) {
              self.setState({ id: data.data, is_published: is_publish });
              self.getConfInfo(self.state.id, true);
              self.setUrlT(self.state.id);
              self.saveBrief(data.data);
            } else {
              const tmp_str = "Изменения сохранены";
              dispatch(userActions.set_error(tmp_str, "success"));
              self.getConfInfo(self.state.id, true);
              self.setUrlT(self.state.id);
            }
          },
          (error) => {
            dispatch(userActions.set_error(error));
          }
        );

  };

  onPublic = (n) => {
    const { id } = this.state;
    const { classes, dispatch } = this.props;

    if (id > 0) this.saveConf(n);
    else
      dispatch(
        userActions.set_error(
          "Необходимо сохранить конференцию перед публикацией",
          "warning"
        )
      );
    //this.addConf(n);
  };

  onAdd = () => {
    this.addConf();
  };

  onSave = () => {
    this.saveConf(-1);
  };

  handleChange = (event) => {
    if (event.target.name == "name")
      this.setState({ name: event.target.value });

    if (event.target.name == "theme")
      this.setState({ theme: event.target.value });

    if (event.target.name == "comment")
      this.setState({ comment: event.target.value });

    if (event.target.name == "contacts")
      this.setState({ contacts: event.target.value });

    if (event.target.name == "faq") this.setState({ faq: event.target.value });

    if (event.target.name == "wifi")
      this.setState({ wifi: event.target.value });

    if (event.target.name == "urlt")
      this.setState({ urlt: event.target.value });

    if (event.target.name == "part_count")
      this.setState({ part_count: event.target.value });

    if (event.target.name == "spons_count")
      this.setState({ spons_count: event.target.value });
  
    if (event.target.name == "brief")
      this.setState({ brief: event.target.value });
  
  };

  changeDate = (index, event) => {
    
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };
  
  changeTime = (index, event) => {
    
    if (index == 0) this.setState({ start_time: event.target.value });
    else this.setState({ end_time: event.target.value });
  };

  handleChangeAdress = (index, col, event) => {
    /*
        let { blds } = this.state;
        let tmp_item = adresses[index];
        tmp_item[col] = event.target.value;
        adresses[index] = {...tmp_item}
        this.setState({adresses:adresses});
        */
  };

  deleteAddr = (id) => {
    const self = this;
    const { selectedBldId } = this.state;
    const { classes, dispatch } = this.props;

    if (selectedBldId > 0) {
      userService.delWrapper(
        `api/building/${selectedBldId}`,
        function (data) {
          
          if (data.status == 200) self.getBuildings();
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, isLoading: false });
            dispatch(userActions.set_error(error, "error"));
          }
        }
      );
    }
  };

  editAddr = (id) => {
    const { blds } = this.state;
    let bldAddr = "";
    let bldName = "";
    let bldEmail = "";
    for (let i = 0; i < blds.length; i++) {
      if (blds[i].id == id) {
        bldAddr = blds[i].address;
        bldName = blds[i].name;
        bldEmail = blds[i].email;
      }
    }

    this.setState({
      bldId: id,
      dAddress: bldAddr,
      dName: bldName,
      dEmail: bldEmail,
      bldDialog: true,
    });
  };

  addAddr = () => {
    this.setState({
      bldId: 0,
      dAddress: "",
      dName: "",
      dEmail: "",
      bldDialog: true,
    });
  };

  closeSpDialog = () => {
    this.setState({ spId: 0, spDialog: false });
  };

  closeBldDialog = (val) => {
    const self = this;
    const { bldId } = this.state;
    const { classes, dispatch } = this.props;

    const post_data = {};

    if (val.address) post_data.address = val.address;
    if (val.name) post_data.name = val.name;
    if (val.email) post_data.email = val.email;
    

    if (post_data && post_data.name) {
      if (bldId > 0) {
        userService.patchWrapperBody(
          `api/building/${bldId}`,
          post_data,
          (data) => {
             if (data.status == 200) self.getBuildings();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/building",
          post_data,
          (data) => {
            

            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      }

      this.setState({ bldDialog: false });
    } else {
      this.setState({ bldDialog: false });
    }
  };

  render() {
    const self = this;
    const {
      id,
      bldAddr,
      sponsors,
      sp_list,
      spId,
      spDialog,
      image,
      streamUrl,
      loadingTickets,
      tickets_tmp,
      bldDialog,
      bldId,
      dName,
      dEmail,
      dAddress,
      selectedBldId,
      start_time,
      end_time,
      start_date,
      end_date,
      value,
      blds,
      format,
      part_count,
      spons_count,
      name,
      theme,
      comment,
      building_id,
      contacts,
      wifi,
      faq,
      is_published,
      urlt,
      brief,
    } = this.state;
    const { classes } = this.props;
    const { selectedConf, isConfSelected } = this.props.user;
    
    let conf_url = window.location.protocol+"//"+window.location.host+"/event/"+id;

    return (
      <div className="layout__wrap">
        {isConfSelected && !selectedConf && (
          <ConferenceNotSelected page={"conf_param"}/>
        )}
        {(!isConfSelected || selectedConf) && (
          <div className="layout__cols">
            <div className="layout__block">
              <div className="form">
                <div className="form__col form__col-small">
                  <div className="form__group">
                    <span className="form__label">Изображение</span>

                    <div className="file">
                      {id > 0 && (
                        <Dropzone onDrop={this.onDrop} class="visually--hidden">
                          {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                              <div
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  width: 250,
                                  height: 250,
                                  background: "#eee",
                                  borderRadius: 5,
                                }}
                                {...getRootProps()}
                                className={classNames("dropzone", {
                                  "dropzone--isActive": isDragActive,
                                })}
                              >
                                <input {...getInputProps()} />

                                {image && (
                                  <img
                                    src={image ? serverUrl + image : ""}
                                    alt=""
                                    class="object-fit is--cover"
                                  />
                                )}
                                {isDragActive ? (
                                  <p
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 110,
                                      left: 15,
                                    }}
                                  >
                                    Загрузить файл
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 110,
                                      left: 15,
                                      width: 220,
                                      background: "#eee",
                                      padding: 10,
                                      borderRadius: 5,
                                    }}
                                  >
                                    {TEXT_DROP_FOR_LOAD}
                                  </p>
                                )}
                              </div>
                            );
                          }}
                        </Dropzone>
                      )}
                      {!id > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            verticalAlign: "center",
                            width: 250,
                            height: 250,
                            backgroundColor: "#eee",
                            borderRadius: 5,
                          }}
                        >
                          <span>Сохраните конференцию чтобы добавить лого</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form__group">
                    <label htmlFor="area1" className="form__label">
                      Контакты организатора
                    </label>

                    <textarea
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="contacts"
					  multiline
                      onChange={this.handleChange}
                      value={contacts}
                    />
                  </div>

                  <div className="form__group">
                    <label htmlFor="area1" className="form__label">
                      Описание конференции
                    </label>

                    <textarea
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="comment"
                      onChange={this.handleChange}
                      value={comment}
                    ></textarea>
                  </div>
                  {0 > 1 && (
                    <div className="form__group">
                      <label htmlFor="area1" className="form__label">
                        Вопросы и ответы
                      </label>

                      <textarea
                        id="area1"
                        className="form__input"
                        placeholder="Текст"
                        name="faq"
                        onChange={this.handleChange}
                        value={faq}
                      ></textarea>
                    </div>
                  )}
                  <div className="form__group">
                    <label htmlFor="area1" className="form__label">
                      Wi-fi на площадке
                    </label>

                    <input
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="wifi"
                      onChange={this.handleChange}
                      value={wifi}
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="area2" className="form__label">
                      Бриф для спикеров
                    </label>

                    <textarea
                      id="area1"
                      className="form__input"
                      placeholder="Текст"
                      name="brief"
                      onChange={this.handleChange}
                      value={brief}
                    ></textarea>
                  </div>
                </div>
                <div className="form__col">
                  <div className="form__group">
                    <label htmlFor="text1" className="form__label">
                      Название конференции
                    </label>
                    <input
                      id="text1"
                      type="text"
                      className="form__input"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                      placeholder="Название конференции"
                    />
                  </div>
                  <div className="form__group">
                    <span className="form__label">Формат мероприятия</span>

                    <div className="radio-buttons">
                      <div
                        className="radio-button"
                        onClick={() => self.setState({ format: "online" })}
                      >
                        <span
                          id="r1"
                          name="category"
                          class={
                            format == "online"
                              ? "radio-button__checked"
                              : "radio-button__part"
                          }
                        >
                          Онлайн
                        </span>
                      </div>
                      <div
                        className="radio-button"
                        onClick={() => self.setState({ format: "hybrid" })}
                      >
                        <span
                          id="r2"
                          name="category"
                          class={
                            format == "hybrid"
                              ? "radio-button__checked"
                              : "radio-button__part"
                          }
                        >
                          Гибрид
                        </span>
                      </div>
                      <div
                        className="radio-button"
                        onClick={() => self.setState({ format: "offline" })}
                      >
                        <span
                          id="r3"
                          name="category"
                          class={
                            format == "offline"
                              ? "radio-button__checked"
                              : "radio-button__part"
                          }
                        >
                          Оффлайн
                        </span>
                      </div>
                    </div>
                  </div>
                  {format != "offline" && (
                    <div className="form__group">
                      <label htmlFor="area1" className="form__label">
                        Ссылка на онлайн трансляцию
                      </label>

                      <input
                        id="area1"
                        className="form__input"
                        placeholder="Ссылка"
                        name="urlt"
                        onChange={this.handleChange}
                        value={urlt}
                      />
                    </div>
                  )}

                  <div className="form__group">
                    <div className="form__group-top">
                      <span className="form__label">Даты проведения</span>
                    </div>

                    <div className="form__group-body">
                      <div className="form__group-rows">
                        <div className="date-wrap form__group-row">
                          <label htmlFor="start">Дата начала</label>
                           <span>{"   "}</span>
                          <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={start_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(0, e)}
                          />
                        </div>

                        <div className="date-wrap form__group-row">
                          <label htmlFor="date">Время начала {"   "}</label>
                          <span>{"   "}</span>
                          <input
                            type="time"
                            value={start_time}
                            class="form__input"
                            id="start"
                            name="trip-start"
                            onChange={(e) => this.changeTime(0, e)}
                          />
                        </div>
                        
                        </div>
                        <div className="form__group-rows">
                        
                        <div className="date-wrap form__group-row">
                          <label htmlFor="start">Дата окончания</label>
                          <input
                            type="date"
                            className="form__input"
                            id="start"
                            value={end_date}
                            name="trip-start"
                            onChange={(e) => this.changeDate(1, e)}
                          />
                        </div>

                        <div className="date-wrap form__group-row">
                          <label htmlFor="date">Время окончания</label>
                          <input
                            type="time"
                            value={end_time}
                            class="form__input"
                            id="start"
                            name="trip-start"
                            onChange={(e) => this.changeTime(1, e)}
                          />
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div className="form__group">
                    <div className="form__group-rows">
                      <div className="form__group-row">
                        <label htmlFor="text2" className="form__label">
                          Количество участников:{" "}{part_count}
                        </label>
                      </div>

                      {id > 0 && (
                        <div class="form__group-top">
                          <span class="form__label">Спонсоры</span>
                          <div style={{ width: 30 }}>
                            <EditButton
                              onClick={self.editSponsors}
                              width="15"
                              height="15"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {format != "online" && (
                    <div class="form__group">
                      <div class="form__group-top">
                        <span class="form__label">Адрес проведения</span>
                        <button
                          type="button"
                          class="form__btn"
                          onClick={this.addAddr}
                        >
                          <svg width="29" height="29">
                            <use href="./img/sprite.svg#add-icon"></use>
                          </svg>
                          <span>Добавить</span>
                        </button>
                      </div>

                      <div class="form__group-body">
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBldId}
                            label="Blds"
                            onChange={(e) => this.setBld(e.target.value)}
                          >
                            {blds.length > 0 &&
                              blds.map((item, index) => (
                                <MenuItem value={item.id}>
                                  {item.address}{" "}
                                  {item.name ? "(" + item.name + ")" : ""}{" "}
                                </MenuItem>
                              ))}
                          </Select>
                          <div
                            style={{
                              width: 20,
                              paddingLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            <DeleteIcon
                              onClick={() =>
                                self.setState({ selectedBldId: null })
                              }
                              width="15"
                              height="15"
                            />
                          </div>
                        </div>
                        {selectedBldId > 0 && (
                          <button
                            type="button"
                            class="form__btn"
                            onClick={(e) => this.editAddr(selectedBldId)}
                          >
                            <span>Редактировать</span>
                            <svg width="29" height="29" fill="none"></svg>
                          </button>
                        )}
                        {selectedBldId > 0 && (
                          <button
                            type="button"
                            class="form__btn"
                            onClick={(e) => this.deleteAddr(selectedBldId)}
                          >
                            <span>Удалить</span>
                            <svg width="29" height="29" fill="none"></svg>
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="form__group">
                    {id > 0 && (
                      <div class="form__group-body">
                        {loadingTickets && (
                          <div className="shadow">
                            <div className="shadow_circle">
                              <CircularProgress />
                            </div>
                          </div>
                        )}

                        {!loadingTickets && (
                          <div class="form__group-top">
                            <span class="form__label">Типы билетов</span>
                            <button
                              type="button"
                              class="form__btn"
                              onClick={self.addTicket}
                            >
                              <svg width="29" height="29">
                                <use href="./img/sprite.svg#add-icon"></use>
                              </svg>
                              <span>Добавить</span>
                            </button>
                          </div>
                        )}
                        {!loadingTickets &&
                          tickets_tmp.map(function (item, index) {
                            return (
                              <div class="form__group-rows">
                                <div
                                  class="form__group-row"
                                  onBlur={() => self.saveTicket(index)}
                                >
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      self.editTicket(
                                        index,
                                        e.target.value,
                                        "name"
                                      )
                                    }
                                    class="form__input"
                                    placeholder="Название"
                                    value={item.name}
                                  />
                                </div>
                                <div
                                  class="form__group-row"
                                  onBlur={() => self.saveTicket(index)}
                                >
                                  <input
                                    type="text"
                                    readonly="readonly"
                                    onClick={self.showTextForBuy}
                                    style={{ backgroundColor: "#D3D4D4" }}
                                    class="form__input"
                                    placeholder="Цена"
                                    value={item.cost}
                                  />
                                </div>

                                <div style={{ width: 30 }}>
                                  <DeleteIcon
                                    onClick={() => self.delTicket(index)}
                                    width="15"
                                    height="15"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="layout__col layout__col-aside">
              <div className="layout__block">
                <h2 className="layout__block-title">Предосмотр</h2>

                <div className="layout__block-monitor">
                  <img
                    src={image ? serverUrl + image : confLogo}
                    alt=""
                    className="layout__block-img"
                  />
                  {format == "hybrid" && <span className="layout__block-label">Гибрид</span>}
                  {format == "online" && <span className="layout__block-label">Онлайн</span>}
                  {format == "offline" && <span className="layout__block-label">Оффлайн</span>}
                </div>

                <div className="layout__inf">
                  <h3 className="layout__inf-title">{name}</h3>
				  <h3>
				  {id>0 && is_published > 0 && <div className="form__group">
                  
                   <a href={conf_url}
                    style={{fontSize:18}}>
                    <span style={{color:'blue', textDecoration: 'underline'}}>{conf_url}</span>
                    </a>
                      <CopyContentIcon
                          onClick={() => navigator.clipboard.writeText(conf_url)}
                          width="20"
                          height="20"
                          color="primary"
                          style={{marginLeft:5,paddingTop:5, cursor: 'pointer'}}
                          />
                  </div>}
				  { is_published==0 && <div className="form__group">
				  Публичная ссылка появится после публикации мероприятия
				  </div>
				  }
				  </h3>

                  <p className="layout__inf-text">
                  {start_date && start_time?start_date+" "+start_time:"не определено"}
                      {" "}
                  {end_date && end_time?end_date+" "+end_time:"не определено"}
                  </p>
                  {selectedBldId && (
                    <p className="layout__inf-text">{bldAddr}</p>
                  )}
                  {!selectedBldId && (
                    <p className="layout__inf-text">Нет адреса</p>
                  )}

                  <ul className="layout__inf-blocks">
                    <li className="layout__inf-block">
                      <div className="layout__inf-media">
                        <p className="layout__inf-contains">
                          <strong>{part_count}</strong> <br />
                          участников
                        </p>
                      </div>
                    </li>
                    <li className="layout__inf-block">
                      <div className="layout__inf-media">
                        <p className="layout__inf-contains">
                          <strong>{spons_count}</strong> <br />
                          спонсоров
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="layout__block-btns">
                  <div className="layout__block-btn">
                    <button
                      className="btn btn--border"
                      type="button"
                      onClick={this.resetConf}
                    >
                      Сбросить
                    </button>
                  </div>

                  <div className="layout__block-btn">
                    {id == 0 && (
                      <button
                        onClick={this.onAdd}
                        className="btn"
                        type="submit"
                      >
                      {SAVE_CONF_TEXT}
                      </button>
                    )}
                    {id > 0 && (
                      <button
                        onClick={this.onSave}
                        className="btn"
                        type="submit"
                      >
                      {SAVE_CONF_TEXT}
                      </button>
                    )}
                  </div>
                  <div className="layout__block-btn" >
                    <button
                      onClick={() => this.onPublic(1 - is_published)}
                      className={id>0?"btn":"btn_disabled"}
                      type="submit"
                      title={id>0?'':"Сперва сохраните конференцию"}
                    >
                      {is_published == 0
                        ? "Опубликовать конференцию"
                        : "Снять с публикации"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <BldDialog
          handleClose={self.closeBldDialog}
          id={bldId}
          dAddress={dAddress}
          dName={dName}
          dEmail={dEmail}
          dialogOpen={bldDialog}
        />

        <SponsorDialog
          handleClose={self.closeSpDialog}
          id={spId}
          sp_list={sponsors}
          all_list={sp_list}
          dialogOpen={spDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCreateConfPage = connect(mapStateToProps)(CreateConfPage);
export { connectedCreateConfPage as CreateConfPage };
