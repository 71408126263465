import React from "react";
import EntityItem from "./EntityItem";

export default class EntityList extends React.Component {
  render() {
    return (
      <div className="tbl">
        <div className="tbl__top">
          <div className="tbl__items">
            {this.props.fields &&
              this.props.fields.map((item, index) => (
                <div className={`tbl__item ${item.class}`}>{item.head}</div>
              ))}
          </div>
        </div>
        <div className="tbl__body">
          {this.props.data &&
            this.props.data.map((item, index) => (
              <EntityItem
                key={item.id}
                fields={this.props.fields}
                data={item}
                actions={this.props.itemActions}
                class={this.props.classes.row(item)}
              />
            ))}
        </div>
      </div>
    );
  }
}
