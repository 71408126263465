import React from "react";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';


import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

import { connect } from "react-redux";
import { history, store } from "../_helpers";
import { confService } from "../_services";

import { SendButton, TextFieldCell, Snack } from "../_components";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { userActions } from "../_actions";
import { serverUrl, agrUrl } from "../_constants";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

//const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;
const loginIsEmail = false;

class RegistrPage extends React.Component {
  constructor(props) {
    super(props);
    // reset login status

    this.state = {
      username: "",
      pwd: "",
      pwd2: "",
      submitted: false,
      wrong: false,
      toShort: false,
      isStudent: true,
      agreeOk: false,
      slotInfoOpen: false,
      userType: "visitor",
    };

    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    console.log(e);
    console.log(store.getState());
    if (
      store.getState().authentication.error &&
      store.getState().authentication.isLoading == false
    ) {
      
      const snText = "Пользователь с таким e-mail уже существует";
      this.setState({ snackText: snText, alertShow: true, err_type: "error" });
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
  }

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open(agrUrl);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const self = this;

    e.preventDefault();

    const { username, pwd, pwd2, isStudent, agreeOk, userType } = this.state;
    const { dispatch } = this.props;

    let snText = "";

    if (agreeOk == false) {
      snText = "Примите соглашение об обработке персональных данных";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (!loginIsEmail && username.length < 3) {
      snText = "Логин должен быть не меньше 3 символов";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });  
    }
	 else if (loginIsEmail && username.length < 3) {
      snText = "Слишком короткий email";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });  
    } else if (!loginIsEmail && confService.validateLogin(username) == false) {
      snText = "Введите корректный логин";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    }
	 else if (loginIsEmail && confService.validateEmail(username) == false) {
      snText = "Введите корректный email";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (pwd.length < 6) {
      // alert('Пароль должен быть не меньше 6 символов');
      snText = "Пароль должен быть не меньше 6 символов";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (confService.validatePassword(pwd) == false) {
      // alert('Пароль должен содержать только латинские буквы и цифры и ');
      snText = "Пароль должен содержать только латинские буквы, цифры и дефис";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (pwd != pwd2) {
      // alert('Пароли не совпадают');
      snText = "Пароли не совпадают";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else if (userType == "") {
      snText = "Выберите тип аккаунта";
      this.setState({
        snackText: snText,
        alertShow: true,
        err_type: "warning",
      });
    } else {
       
      // history.push('/');
      // if (agreeOk == true)
      dispatch(
        userActions.registr({
          username,
          password: pwd,
          isStudent: userType == "visitor",
        })
      );
    }
  };

  render() {
    console.log(this.props);

    const { error, isLoading } = this.props;

    const {
      slotInfoOpen,
      username,
      submitted,
      pwd,
      pwd2,
      isStudent,
      agreeOk,
      snackText,
      err_type,
      alertShow,
      userType,
	  showPassword1,
	  showPassword2,
    } = this.state;

    let errorMsg = "Неверный токен";
    if (error) {
      if (error === "Forbidden") errorMsg = "Неправильный токен";
    }

    return (
      <div style={{ display:'flex',justifyContent: "center",alignItems:'center',height: '100vh' }}>
        <div style={{ paddingLeft:30, paddingRight:30 }}>
          <form
            name="form"
            onSubmit={this.handleSubmit}
            style={{ justifyContent: "center" }}
          >
            <div style={{ verticalAlign: "center" }} />
            <div className={submitted && !username ? " has-error" : ""}>
              <TextFieldCell
                id="login"
                label={loginIsEmail?"Email пользователя":"Логин пользователя"}
                name="username"
                type="text"
                value={username}
                onChange={this.handleChange}
				autoFocus
              />
            </div>
            <div className={submitted && !username ? " has-error" : ""}>
			   <TextField
				style={{display:'flex',marginBottom:20}}
                id="pwd"
                label="Пароль"
                name="pwd"
                type={showPassword1?"text":"password"}
                value={pwd}
                onChange={this.handleChange}
				 InputProps={{
						style:{fontSize: 24,    
						fontWeight: "bold",
						color: "#0b1218"},
						 endAdornment:  
						   <InputAdornment position="end">
							  <IconButton
								onClick={()=>this.setState({showPassword1:!showPassword1})}
								edge="end"
							  >
								{showPassword1 ? <Visibility /> : <VisibilityOff />}
							  </IconButton>
							</InputAdornment>
						 
					   }}
              />

            </div>
            <div className={submitted && !username ? " has-error" : ""}>
			<TextField
				style={{display:'flex',marginBottom:20}}
                id="pwd"
                label="Пароль"
                name="pwd2"
                type={showPassword2?"text":"password"}
                value={pwd2}
                onChange={this.handleChange}
				 InputProps={{
						style:{fontSize: 24,    
						fontWeight: "bold",
						color: "#0b1218"},
						 endAdornment:  
						   <InputAdornment position="end">
							  <IconButton
								onClick={()=>this.setState({showPassword2:!showPassword2})}
								edge="end"
							  >
								{showPassword2 ? <Visibility /> : <VisibilityOff />}
							  </IconButton>
							</InputAdornment>
						 
					   }}
              />
            </div>
            <FormGroup>
              <RadioGroup
                aria-labelledby="demo"
                name="user_type"
                value={userType}
                row
                onChange={(e) => this.setState({ userType: e.target.value })}
              >
                <FormControlLabel
                  value="visitor"
                  control={<Radio />}
                  label="Посетитель"
                />
                <FormControlLabel
                  value="org"
                  control={<Radio />}
                  label="Организатор"
                />
              </RadioGroup>
            </FormGroup>

            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontSize: 20,
                marginLeft: -3,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                color="default"
                style={{ paddingLeft: 0, paddingTop: 0 }}
                checked={agreeOk}
                onClick={() => this.setState({ agreeOk: !agreeOk })}
              />
              <p>
                Я ознакомился с<span> </span>
                <span
                  style={{ color: "blue", textDecoration: "underline" }}
                  onClick={this.clickAgr}
                >
                  Соглашением об обработке персональных данных
                </span>
                 <span> и даю своё согласие на их обработку</span>
              </p>
            </div>
            <div
              className="form-group"
              style={{
                marginTop: 5,
                justifyContent: "space-between",
                flex: 1,
                display: "flex",
              }}
            >
              <CustomizedButton
                style={{ marginBottom: 5 }}
                onClick={this.handleSubmit}
                id="category-button-add"
                type="submit"
                title="Регистрация"
                prim
              />

              <a
                onClick={() => history.push("/login")}
                style={{ marginTop: 10, fontSize: 20 }}
              >
                <span style={{ color: "blue" }}>Назад</span>
              </a>
            </div>
          </form>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}

        <Snack
          open={alertShow}
          variant={err_type}
          text={snackText}
          onClose={this.onCloseSnack}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, error, isLoading } = state.authentication;
  return {
    loggingIn,
    isLoading,
    error,
  };
}

const connectedRegistrPage = connect(mapStateToProps)(RegistrPage);
export { connectedRegistrPage as RegistrPage };
