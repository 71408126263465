import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Checkbox from "@mui/material/Checkbox";


export default function AddDialog(props) {
  const [value, setValue] = React.useState("");

  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [isServiceSlot, setServiceSlot ] = React.useState(false);

  const {
    handleClose,
    label,
    title,
    dialogOpen,
    onFileUpload,
    dialogType,
    fileStatus,
  } = props;

  function addTheme() {
    const tmpObj = { name: value };

    tmpObj.description = description;
    
    if (dialogType == "theme" && isServiceSlot)
            tmpObj.isServiceSlot = true;

    handleClose(tmpObj);
    setCount(0);
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setServiceSlot(false);
  }

  function cancelTheme() {
    handleClose(null);
    setCount(0);
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setServiceSlot(false);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);

    if (acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
      
      <div>
       <Checkbox checked={isServiceSlot} onClick = {()=>setServiceSlot(!isServiceSlot)}/>
       <span style={{fontSize:18}}>Служебный слот</span>
       </div>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {(dialogType == "speaker" || (dialogType == "theme" && !isServiceSlot)) && (
          <TextField
            autoFocus
            margin="dense"
            id="desc"
            label="Описание"
            fullWidth
            variant="standard"
            value={description}
            onChange={(e) => {
              setDesc(e.target.value);
              setCount(count + 1);
            }}
          />
        )}

        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label="Телефон"
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label="Город"
            fullWidth
            variant="standard"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label="Комментарий"
            fullWidth
            variant="standard"
            value={comm}
            onChange={(e) => {
              setComm(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "theme" && !isServiceSlot && (
          <div style={{ marginTop: 10 }}>
            <div style={{ paddingBottom: 5 }}>Файл презентации</div>
            <Dropzone onDrop={onDrop} class="visually--hidden">
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    width: 400,
                    height: 50,
                    background: "#36B2F1",
                    borderRadius: 5,
                  }}
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                >
                  <input {...getInputProps()} />

                  {fileStatus == 0 &&
                    (isDragActive ? (
                      <p style={{ fontSize: 12 }}>Загрузить файл</p>
                    ) : (
                      <p style={{ fontSize: 12, padding: 10, borderRadius: 5 }}>
                        Перетащите файл сюда или нажмите для загрузки файла
                      </p>
                    ))}
                  {fileStatus == 1 && (
                    <p style={{ fontSize: 12, padding: 10 }}>Файл загружен</p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={addTheme}>Добавить</Button>
        <Button onClick={cancelTheme}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
}
