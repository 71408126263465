import React from "react";
import i18next from "i18next";
import { reduxLoader } from "../_services/reduxLoader.service";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { userActions, appActions } from "../_actions";
import { confService } from "../_services";

class ConferenceNotSelected extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conferenceId: 0,
    };
  }

  componentDidMount() {
    reduxLoader.orgConferences();
  }

  prepareOrgConferencesOptions = () => {
    let options = {0: i18next.t("Choose conference...")};
    this.props.orgConferences.forEach(function (value) {
      options[value.id] = value.name;
    });
    return options;
  };

  render() {
    const options = this.prepareOrgConferencesOptions();

    return (
      <div className="conference-not-selected">
      <div>
        <label htmlFor="text1" className="form__label">
          {i18next.t("Conference is not selected")}
        </label>
        <br/>
        <Select
          margin="dense"
          value={this.state.conferenceId}
          label="label"
          onChange={(e) => {
            if (e.target.value) {
              confService.confInfo(
                e.target.value,
                (data) => {
                  if (data.status === 200) {
                    this.props.dispatch(userActions.set_conf({ ...data.data }));
                  }
                },
                (error) => {
                  this.props.dispatch(userActions.set_error(i18next.t("Unknown error. Please contact the support.")));
                }
              );
              this.props.dispatch(appActions.setpage(this.props.page, e.target.value));
            }
            this.setState({ conferenceId: e.target.value });
          }}
        >
          {Object.entries(options).length !== 0 &&
            Object.keys(options).map((item, index) => (
              <MenuItem value={item}>{options[item]}</MenuItem>
            ))}
        </Select>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { orgConferences } = state;

  return {
    orgConferences,
  };
}

const connectedConferenceNotSelected = connect(mapStateToProps)(ConferenceNotSelected);
export { connectedConferenceNotSelected as ConferenceNotSelected };
