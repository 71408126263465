import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { SendButton, TextFieldCell, Snack } from "../_components";
import StylizedDialogTitle from "../_components/StylizedDialogTitle";
import CustomizedButton from "../_components/buttons/CustomizedButton";

export default class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { name: props.isEdit ? props.editItem.name : "" },
      name: "",
      commonError: "",
      id: props.isEdit ? props.editItem.id : 0,
      error: {},
    };
  }

  handleChange = (e) => {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
    });
  };

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };

  onSave = () => {
    const data = this.state.item.name;
    // data.updateFields = ['name', 'description', 'part', 'icon_base64'];

    this.props.onSave(data);
  };

  onCancel = () => () => {
    this.props.onClose();
  };

  render() {
    const { commonError } = this.state;

    return (
      <Dialog open maxWidth="md" onClose={this.onCancel}>
        <StylizedDialogTitle
          dialogTitle={
            this.state.id == 0
              ? "Добавление новой компании"
              : "Редактирование компании"
          }
        >
          {commonError !== "" && (
            <Typography variant="body1" color="error">
              {this.state.commonError}
            </Typography>
          )}
        </StylizedDialogTitle>
        <DialogContent>
          <TextFieldCell
            id="newcat-text-name"
            label="Название"
            name="name"
            type="text"
            value={this.state.item.name}
            error={this.state.error.name}
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomizedButton
            id="newcat-button-cancel"
            onClick={this.onCancel()}
            title="Отмена"
          />
          <SendButton
            id="newcat-button-save"
            onClick={this.onSave}
            title="Сохранить"
            prim
          />
        </DialogActions>
        <Snack
          open={this.state.alertShow}
          variant="error"
          text={this.state.snackText}
          onClose={this.onCloseSnack}
        />
      </Dialog>
    );
  }
}
