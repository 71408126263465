import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { serverUrl } from "../_constants";
import "../css/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  minWidth: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const no_text = "уточняется";
const no_url_text =
  "Смотреть трансляцию";

export default function SpeakerInfo(props) {
  const [count, setCount] = React.useState(0);

  const { handleClose, data, open } = props;

  React.useEffect(() => {});


  function openUrl() {
    if (data.url_online) {
      if (data.url_online.indexOf("https:") != 0 && data.url_online.indexOf("http:") != 0)
        window.open("https://" + data.url_online);
    
      else window.open(data.url_online);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
            
            {data.photo && <div style={{display:'flex', justifyContent: 'center'}}>
            <img
            style={{maxHeight:300, width: 'auto',marginBottom:10}}
            src={data.photo ? serverUrl + data.photo : ''}
            alt=""
            className="object-fit is--cover"
            /></div>}
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Имя: {data.name ? data.name : no_text}
          </Typography>
          {data.city && <Typography id="transition-modal-title" variant="h6" component="h2">
            Город: {data.city}
          </Typography>}
          <Typography id="transition-modal-description"  variant="h6" sx={{ mt: 2 }} style={{whiteSpace: 'pre-wrap' }}>
            Описание: {data.description ? data.description : no_text}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}
