import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { history } from "../_helpers";
import { SendButton, TextFieldCell, Snack } from "../_components";
import "../css/style.css";

import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userService } from "../_services";

import MemberDialog from "./MemberDialog";
import MemberInfo from "./MemberInfo";
import RoleDialog from "./RoleDialog";
import RoleListDialog from "./RoleListDialog";
import RulesDialog from "./RulesDialog";
import RoleItem from "./RoleItem";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { userActions, appActions } from "../_actions";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";

class RolePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      conf_list: [],
      team: [],
      emailStr: "",
      nameStr: "",
      phoneStr: "",
      teamroles: [],
      openDialog: false,
      dName: "",
      dPhone: "",
      dMail: "",
      roles: [],
      roleDialog: false,
      roleId: 0,
      confId: 0,
      id: 0,
      rlistDialog: false,
      rolePerm: [],
      fullPerm: [],
      fName: "",
      fRole: "",
      fEmail: "",
      fConf: "",
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    //this.props.dispatch(userActions.teamorg());
    console.log(this.props);

    const conf_list = [];

    if (this.props.user && this.props.user.conflist) {
      for (let i = 0; i < this.props.user.conflist.length; i++)
        conf_list.push({
          id: this.props.user.conflist[i].id,
          name: this.props.user.conflist[i].name,
        });
      if (conf_list.length > 0) this.setState({ conf_list });
    }

    let self = this;
    this.getRole();
  }

  closeDialog = (val) => {
    console.log(val);

    const { id } = this.state;

    if (val) {
      this.setState({ openDialog: false, isLoading: true });
      let self = this;

      const postData = { name: val.name };

      if (id == 0 && val.email) postData.email = val.email;
      if (val.phone) postData.phone = val.phone;
      if (val.city) postData.city = val.city;

      if (id == 0) {
        userService.postWrapper(
          "api/team",
          postData,
          (data) => {
            self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      } else {
        userService.patchWrapperBody(
          `api/team/${id}`,
          postData,
          (data) => {
            self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      }
    } else this.setState({ openDialog: false, isLoading: false });
  };

  saveRole = (data) => {
    console.log(data);
    console.log(this.state);
    const self = this;

    const { id, confId, roleId } = this.state;

    if (data) {
      this.setState({ isLoading: true });

      const role_Id = data.roleId;
      const conf_Id = data.confId;
      const team_Id = data.teamId;
      let reqStr = `api/team/${team_Id}?attach_role_id=${role_Id}`;
      
      if (confId>0)
          reqStr+='&attach_conference_id=${conf_Id}';

      if (this.state.roleId > 0 && this.state.confId > 0)
        reqStr = `${reqStr}&detach_conference_id=${confId}&detach_role_id=${roleId}`;

      // let patchData = {attach_conference_id:confId,attach_role_id:roleId};

      userService.patchWrapper(
        reqStr,
        (data) => {
          console.log(data);

          self.getRole();
        },
        (error) => {
          if (error != "") {
            self.setState({ error });
            self.props.dispatch(userActions.set_error(error))
          }
        }
      );
    }

    this.setState({
      roleDialog: false,
      id: 0,
      roleId: 0,
      confId: 0,
    });
  };

  getRole = () => {
    let self = this;
    this.setState({ isLoading: true });
    
    /*

    userService.getWrapper(
      "api/role",
      (data) => {
        console.log(data);

        if (data.data) self.setState({ roles: [...data.data] });
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
    */
    self.getTeam();
  };

  getRoleInfo = (id) => {
    let self = this;

    userService.getWrapper(
      `api/role/${id}`,
      (data) => {
        console.log(data);

        const perms = [...data.data.permissions.map((e) => e.key)];

        console.log(perms);

        const fP = [...data.data.full_permissions_list];
        const permList = [];

        for (let i = 0; i < fP.length; i++) {
          const rName = fP[i];
          const tmpObj = { name: rName, val: false };

          if (perms.indexOf(rName) >= 0) tmpObj.val = true;

          permList.push(tmpObj);
        }

        self.setState({
          rolePerm: permList,
          fullPerm: fP,
          roleId: data.data.id,
          roleName: data.data.name,
        });
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  showInfo = (data) => {
    console.log(data);

    let userData = { ...data };

    let fUsers = this.state.team.filter((e) => e.id == userData.id);

    if (fUsers.length > 0) {
      userData.city = fUsers[0].city;
      userData.phone = fUsers[0].phone;
    }

    this.setState({ infoOpen: true, infoData: userData });
  };

  getTeam = () => {
    let self = this;
    
    const { conf_list, roles } = this.state;

    userService.getWrapper(
      "api/team",
      (data) => {
        console.log(data);
        if (data.data) {
          const tmp_roles = [];

          for (let i = 0; i < data.data.length; i++) {
            for (let j = 0; j < data.data[i].roles.length; j++) {
              let conf_name = "";
              let role_name = "";
              let { role_id } = data.data[i].roles[j].pivot;
              let conf_id = data.data[i].roles[j].pivot.conference_id;

              for (let k = 0; k < roles.length; k++)
                if (roles[k].id == role_id)
                  role_name = roles[k].name;

              for (let k = 0; k < conf_list.length; k++)
                if (conf_list[k].id == conf_id)
                  conf_name = conf_list[k].name;

              tmp_roles.push({
                id: data.data[i].id,
                name: data.data[i].name,
                email: data.data[i].email,
                conference_id: conf_id,
                role_id: role_id,
                role_name: role_name,
                conf_name: conf_name,
              });

              console.log(tmp_roles);
            }
          }


          self.setState({
            team: [...data.data],
            roles_list: tmp_roles,
            isLoading: false,
          });

        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  editRole = (id, val) => {
    const self = this;

    userService.patchWrapperBody(
      `api/role/${id}`,
      { name: val },
      (data) => {
        console.log(data);

        self.getRole();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  addRole = (val) => {
    const self = this;

    userService.postWrapper(
      "api/role",
      { name: val },
      (data) => {
        console.log(data);

        self.getRole();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  saveRule = (data) => {
    if (data == "")
      this.setState({
        rulesDialog: false,
        rolePerm: [],
        roleName: "",
        fullPerm: [],
      });
    else {
      console.log(data);

      const rulesData = [];
      const tmpObj = {};

      for (let i = 0; i < data.length; i++) {
        if (data[i].val == true) tmpObj[data[i].name] = 1;
        else tmpObj[data[i].name] = 0;
      }

      const self = this;

      this.setState({
        rulesDialog: false,
        rolePerm: [],
        roleName: "",
        fullPerm: [],
      });

      userService.postWrapper(
        `api/permissions/${this.state.roleId}`,
        { permissions: { ...tmpObj } },
        (data) => {
          console.log(data);
        },
        (error) => {
          if (error != "") {
            self.setState({ error, isLoading: false });
          }
        }
      );
    }
  };

  rulesOpen = (data) => {
    console.log(data);
    if (data.id) this.getRoleInfo(data.id);
    this.setState({ rulesDialog: true });
  };

  delRole = (id) => {
    const self = this;

    userService.delWrapper(
      `api/role/${id}`,
      (data) => {
        console.log(data);

        self.getRole();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  storeChange = (e) => {};

  userEdit = (id) => {
    const { team } = this.state;

    let name = "";
    let phone = "";
    let city = "";

    for (let i = 0; i < team.length; i++) {
      if (team[i].id == id) {
        name = team[i].name;
        phone = team[i].phone;
        city = team[i].city;
        break;
      }
    }

    this.setState({
      id,
      openDialog: true,
      dName: name,
      dPhone: phone,
      dCity: city,
    });
  };

  userAction = (id) => {
    this.setState({
      id,
      roleDialog: true,
      confId: 0,
      roleId: 0,
      isUser: true,
    });
  };

  roleAction = (val) => {
    console.log(val);
    this.setState({
      id: val.id,
      roleDialog: true,
      confId: val.conference_id,
      roleId: val.role_id,
      isUser: false,
    });
  };

  delUserAction = (val) => {
    console.log(val);
    this.setState({ isLoading: true });
    const self = this;

    const reqStr = `api/team/${val.id}`;

    userService.delWrapper(
      reqStr,
      (data) => {
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  openRoles = () => {
    this.setState({ rlistDialog: true });
  };

  delRoleAction = (val) => {
    console.log(val);
    this.setState({ isLoading: true });
    const self = this;

    const reqStr = `api/team/${val.id}?detach_conference_id=${val.conference_id}&detach_role_id=${val.role_id}`;

    userService.patchWrapper(
      reqStr,
      (data) => {
        // let c_id = self.props.conf_id;
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  addConnect = () => {
    this.setState({
      id: 0,
      roleDialog: true,
      confId: 0,
      roleId: 0,
      isUser: true,
    });
  };

  editFilter = (name, val) => {
    console.log(name, val);
    if (name == "name") this.setState({ fName: val });
    else if (name == "role") this.setState({ fRole: val });
    else if (name == "email") this.setState({ fEmail: val });
    else if (name == "conf") this.setState({ fConf: val });
  };

  render() {
    const self = this;
    const {
      isLoading,
      team,
      roles_list,
      rulesDialog,
      rolePerm,
      fullPerm,
      roleName,
      fName,
      fRole,
      fEmail,
      fConf,
      roles,
      conf_list
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Роли на конференциях</h1>

          <div
            className="layout__btn"
            style={{ minWidth: 550, display: "flex", flexDirection: "row" }}
          >
            <button
              class="btn"
              style={{ marginRight: 20 }}
              onClick={self.addConnect}
            >
              Добавить роль
            </button>
            <button
              class="btn"
              style={{ marginRight: 10 }}
              onClick={self.openRoles}
            >
              Список ролей
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                <input
                  type="text"
                  placeholder="Поиск по имени"
                  value={fName}
                  onChange={(e) => self.editFilter("name", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--level">
                <input
                  type="text"
                  placeholder="Уровень"
                  value={fRole}
                  onChange={(e) => self.editFilter("role", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Конференции"
                  value={fConf}
                  onChange={(e) => self.editFilter("conf", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Поиск по email"
                  value={fEmail}
                  onChange={(e) => self.editFilter("email", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text" />
            </div>
          </div>

          <div className="tbl__body">
            {roles_list &&
              roles_list
                .filter(
                  (e) => e.name.toLowerCase().indexOf(fName.toLowerCase()) >= 0
                )
                .filter(
                  (e) =>
                    fEmail == "" ||
                    (e.email &&
                      e.email.toLowerCase().indexOf(fEmail.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    fRole == "" ||
                    (e.role_name &&
                      e.role_name.toLowerCase().indexOf(fRole.toLowerCase()) >=
                        0)
                )
                .filter(
                  (e) =>
                    fConf == "" ||
                    (e.conf_name &&
                      e.conf_name.toLowerCase().indexOf(fConf.toLowerCase()) >=
                        0)
                )
                .map((item, index) => (
                  <RoleItem
                    data={item}
                    isUser={false}
                    showInfo={self.showInfo}
                    userAction={(e) => self.userAction(e)}
                    roleAction={(e) => self.roleAction(e)}
                    delAction={(e) => self.delRoleAction(e)}
                  />
                ))}
          </div>
        </div>

        <RoleDialog
          handleClose={self.saveRole}
          roles_list={roles}
          conf_list={conf_list}
          old_confId={self.state.confId}
          old_roleId={self.state.roleId}
          dialogOpen={self.state.roleDialog}
          needPeople={true}
          team={self.state.team}
        />

        <RoleListDialog
          handleClose={() => self.setState({ rlistDialog: false })}
          roles_list={self.state.roles}
          addRole={self.addRole}
          editRole={self.editRole}
          delRole={self.delRole}
          openRules={self.rulesOpen}
          dialogOpen={this.state.rlistDialog}
        />
        <RulesDialog
          handleClose={self.saveRule}
          rolePerm={rolePerm}
          fullPerm={fullPerm}
          roleName={roleName}
          dialogOpen={rulesDialog}
        />
        <MemberInfo
          handleClose={() => self.setState({ infoOpen: false })}
          data={self.state.infoData}
          open={self.state.infoOpen}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedRolePage = connect(mapStateToProps)(RolePage);
export { connectedRolePage as RolePage };
