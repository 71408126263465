import React from "react";
import PropTypes from "prop-types";

import "../css/style.css";

export default class HelpInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <span>
          На этой странице будет список всех ваших конференций. Любую из них
          можно выбрать или отредактировать просто кликнув по заголовку или
          изображению.
          <br />
          <br />
          Редактор программы и другие опции конференции в меню слева станут
          доступны после выбора активной конференции.
          <br />
          <br />
          В меню слева в разделе «Команда организаторов и роли» вы также можете
          добавить коллег и назначить им роли. Также, выбрав нужные разделы, вы
          можете добавить в базы ваших спикеров, спонсоров, с которыми вы
          работаете и площадки проведения мероприятий.
          <br />
          <br />
          Перед публикацией своей первой конференции вы можете посмотреть, как
          она будет выглядеть для посетителей, выбрав соответствующую опцию в
          меню слева. А после того, как посетители зарегистрируются – вы сможете
          посмотреть их список, выбрав раздел «Участники конференций».
        </span>
      </div>
    );
  }
}
