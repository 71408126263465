import React from "react";
import PropTypes from "prop-types";
import { history, store } from "../_helpers";
import "../css/style.css";

import { storeData, setStore } from "../store.js";
import { connect } from "react-redux";

import MemberDialog from "./MemberDialog";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CheckItem from "./CheckItem";
import SpeakerItem from "./SpeakerItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { userActions, appActions } from "../_actions";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import { SendButton, TextFieldCell, Snack } from "../_components";
import { userService } from "../_services";

class SpeakersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      conf_list: [],
      team: [],
      teamroles: [],
      openDialog: false,
      dName: "",
      dPhone: "",
      dMail: "",
      dCity: "",
      dDesc: "",
      dComm: "",
      roles: [],
      roleDialog: false,
      roleId: 0,
      confId: 0,
      id: 0,
      conf_id: 0,
      phoneFilter: "",
      nameFilter: "",
      cityFilter: "",
      descFilter: "",
      emailFilter: "",
      commentFilter:'',
      allChecked: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    // this.props.dispatch(userActions.teamorg());
    

    const conf_list = [];

    /*
        if (this.props.user && this.props.user.conflist)
        {
            for (let i=0;i<this.props.user.conflist.length;i++)
                conf_list.push({id:this.props.user.conflist[i].id,name:this.props.user.conflist[i].name});
            if (conf_list.length>0)
                this.setState({conf_list:conf_list});
        }
        */

    let self = this;
    // this.getRole();
    this.getTeam();
    this.getConfs();
  }

  getConfs = () => {
    const self = this;
    userService.getWrapper(
      "api/conference",
      (data) => {
        if (data.status == 200) {
          self.setState({
            conf_list: data.data.filter((e) => e.contracts_for_speakers == 0),
          });
        }
      },
      (error) => {}
    );
  };
  
  uploadFile = (selectedFile) => {
    const data_upload = new FormData();
    data_upload.append("photo", selectedFile);

    const { id } = this.state;

    if (id == 0) {
      this.setState({ imgStatus: 1, dataImage: data_upload });
    } else {
      // this.uploadImage(data_upload,id);
      this.setState({ imgStatus: 1, dataImage: data_upload });
    }
  };
  
  uploadImage = (data_upload, id) => {
    const self = this;
    
    console.log(data_upload);

    userService.uploadFile(
      `api/speaker/${id}/upload`,
      data_upload,
      (data) => {
        console.log(data);
        
        if (data && data.data && data.data.photo)
          self.setState({ spImage: data.data.photo });
        if (self.state.imgStatus == 1) self.getTeam();
      },
      (error) => {
        console.log(error);
      }

    );
  };

  editFilter = (name, val) => {
    
    if (name == "name") this.setState({ nameFilter: val });
    else if (name == "desc") this.setState({ descFilter: val });
    else if (name == "city") this.setState({ cityFilter: val });
    else if (name == "email") this.setState({ emailFilter: val });
    else if (name == "phone") this.setState({ phoneFilter: val });
    else if (name == "comment") this.setState({ commentFilter: val });
  };

  closeDialog = (val) => {

    const { id, imgStatus, dataImage } = this.state;

    if (val) {
      this.setState({ openDialog: false, isLoading: true });
      let self = this;

      const postData = { ...val };
      if (id == 0) {
        Object.keys(postData).forEach((key) => {
          if (postData[key] == null || postData[key] == "")
            delete postData[key];
        });

        userService.postWrapper(
          "api/speaker",
          postData,
          (data) => {
              
              
            if (imgStatus == 1 && data.data > 0) {
              self.setState({ id: data.data });
              self.uploadImage(dataImage, data.data);
            } else self.getTeam();
            
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      } else {
        userService.patchWrapperBody(
          `api/speaker/${id}`,
          postData,
          (data) => {
              
            if (imgStatus == 1 && id > 0) {
              self.uploadImage(dataImage, id);
            } else self.getTeam();  

          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error))
            }
          }
        );
      }
    } else this.setState({ openDialog: false, 
                            isLoading: false,
                            imgStatus: 0,
                            spImage: ""});
  };

  getTeam = () => {
    let self = this;

    userService.getWrapper(
      "api/speaker",
      (data) => {
        
        if (data.data) {
          const newData = data.data.map((e) => ({ ...e, checked: false }));
          self.setState({ team: [...newData], isLoading: false });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  addTeamMember = () => {
    // ()=>self.props.dispatch(appActions.setpage('new_member'))}
    this.setState({
      openDialog: true,
      id: 0,
      dName: "",
      dPhone: "",
      dEmail: "",
      dDesc: "",
      dCity: "",
      dComm: "",
      imgStatus: 0,
      spImage: "",
    });
  };

  storeChange = (e) => {};

  onSpeakerCheck = (id) => {
    const { team } = this.state;

    const newTeam = team.map((e) => {
      if (e.id == id) {
        let newCheck = !e.checked;
        return { ...e, checked: newCheck };
      }
      return { ...e };
    });

    this.setState({ team: newTeam });
  };

  userEdit = (id) => {
    const { team } = this.state;

    let name = "";
    let phone = "";
    let email = "";
    let comment = "";
    let desc = "";
    let city = "";
     let spImage = "";

    for (let i = 0; i < team.length; i++) {
      if (team[i].id == id) {
        name = team[i].name;
        phone = team[i].phone;
        email = team[i].email;
        desc = team[i].description;
        comment = team[i].comment;
        city = team[i].city;
         spImage = team[i].photo;
       break;
      }
    }

    this.setState({
      id,
      openDialog: true,
      dName: name,
      dPhone: phone,
      dCity: city,
      dComm: comment,
      dDesc: desc,
      dMail: email,
       spImage,
      imgStatus: 0,
    });
  };

  delUser = (val) => {
    console.log(val);
    this.setState({ isLoading: true });
    const self = this;

    const reqStr = `api/speaker/${val.id}`;

    userService.delWrapper(
      reqStr,
      (data) => {
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error))
        }
      }
    );
  };

  assignSpeakers = () => {
    const { team, conf_id } = this.state;

    if (conf_id > 0) {
      const fTeam = team.filter((e) => e.checked);
     // alert(fTeam.length);
      let spStr = "";
      for (let i = 0; i < fTeam.length; i++) spStr += `${fTeam[i].name}, `;

      if (spStr.length > 0) alert(spStr);
      else alert("Выберите спикеров");
    } else alert("Выберите конференцию");
  };

  checkAll = () => {
    const { allChecked, team } = this.state;
    const newTeam = team.map((e) => ({ ...e, checked: !allChecked }));

    this.setState({ allChecked: !allChecked, team: newTeam });
  };

  render() {
    const self = this;
    const {
      isLoading,
      team,
      roles_list,
      conf_list,
      conf_id,
      nameFilter,
      descFilter,
      cityFilter,
      phoneFilter,
      emailFilter,
      commentFilter,
      allChecked,
    } = this.state;
    const { classes } = this.props;

    /*
     sort(function(a, b) {
                        if (b.name < a.name) {
                                return -1;
                        }
                        if (b.name > a.name) {
                            return 1;
                        }

                        return 0;
                    }).
                    */

    /*
    <div class="layout__contains" style={{paddingTop:0, marginBottom:10}}>
                <h3 class="layout__title">Выбрать конференцию</h3>
                <div>
                   <FormControl sx={{ m: 1, minWidth: 420 }}>
        <InputLabel id="demo-controlled-open-select-label">Конфереция</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          label="Конфереция"
          value = {conf_id}
          onChange={(e)=>self.setState({conf_id:e.target.value})}
        >
          <MenuItem value="">
            <em>Не выбрана</em>
          </MenuItem>
          {conf_list.length>0 && conf_list.map((e)=> (
          <MenuItem value={e.id}>{e.name}</MenuItem>
          ))}

        </Select>
      </FormControl>
                </div>
                <div class="layout__btn">
						<button class="btn" onClick={self.assignSpeakers}
                        >Назначить спикеров</button>
					</div>
                </div>

                */

    /*
        <div class="calendar__body--name">
                              <FormGroup>
                              <FormControlLabel control={<Checkbox checked={allChecked} onClick={this.checkAll} />} label="Все" />

                            </FormGroup>
                            </div>
                            */

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">Спикеры</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addTeamMember}>
              Новый спикер
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                <input
                  type="text"
                  placeholder="Поиск по имени"
                  value={nameFilter}
                  onChange={(e) => self.editFilter("name", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Поиск по описанию"
                  value={descFilter}
                  onChange={(e) => self.editFilter("desc", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Поиск по email"
                  value={emailFilter}
                  onChange={(e) => self.editFilter("email", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder="Поиск по телефону"
                  value={phoneFilter}
                  onChange={(e) => self.editFilter("phone", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--city">
                <input
                  type="text"
                  placeholder="Поиск по городу"
                  value={cityFilter}
                  onChange={(e) => self.editFilter("city", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input type="text" placeholder="Поиск по комментариям" 
                value={commentFilter}
                  onChange={(e) => self.editFilter("comment", e.target.value)}
                />
              </div>
              <div className="calendar__body--text" style={{ width: 60 }} />
            </div>
          </div>

          <div className="tbl__body">
            {team &&
              team
                .filter(
                  (e) =>
                    e.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0
                )
                .filter(
                  (e) =>
                    descFilter == "" ||
                    (e.description &&
                      e.description
                        .toLowerCase()
                        .indexOf(descFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    cityFilter == "" ||
                    (e.city &&
                      e.city.toLowerCase().indexOf(cityFilter.toLowerCase()) >=
                        0)
                )
                .filter(
                  (e) =>
                    phoneFilter == "" ||
                    (e.phone &&
                      e.phone
                        .toLowerCase()
                        .indexOf(phoneFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    emailFilter == "" ||
                    (e.email &&
                      e.email
                        .toLowerCase()
                        .indexOf(emailFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    commentFilter == "" ||
                    (e.comment &&
                      e.comment
                        .toLowerCase()
                        .indexOf(commentFilter.toLowerCase()) >= 0)
                )
                .map((item, index) => (
                  <SpeakerItem
                    data={item}
                    checked={item.checked}
                    isUser
                    onCheck={self.onSpeakerCheck}
                    userEdit={(e) => self.userEdit(e)}
                    delAction={(e) => self.delUser(e)}
                  />
                ))}
          </div>
        </div>
        <MemberDialog
          handleClose={self.closeDialog}
          label="Cпикер"
          title="Спикер"
          id={this.state.id}
          dName={this.state.dName}
          dPhone={this.state.dPhone}
          dMail={this.state.dMail}
          dComm={this.state.dComm}
          dDesc={this.state.dDesc}
          dCity={this.state.dCity}
          dialogOpen={this.state.openDialog}
          imageStatus={this.state.imgStatus}
          image={this.state.spImage}
          uploadImage={self.uploadFile}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedSpeakersPage = connect(mapStateToProps)(SpeakersPage);
export { connectedSpeakersPage as SpeakersPage };
